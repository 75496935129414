import { useState } from "react";
import Typography from "@mui/material/Typography";
import "../Artefacts.scss";
import { deleteArtifactByIdAsync } from "../../../Services/Artifacts";
import { Delete } from "@mui/icons-material";
import GeneralModalDelete from "../../../Helpers/CommonComponents/GeneralDeleteModal";
import { SecondaryButton } from "../../../Helpers/Themes";

export default function Deletemodal(props) {
  const { id, DataUser } = props;
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deletelogic = async () => {
    await deleteArtifactByIdAsync(DataUser.token, id);
    props.Reload();
    handleClose();
  };
  return (
    <div style={{ display: "inline-block" }}>
      <SecondaryButton style={{ display:DataUser.user.result.rol !== "admin"? "none":"", }} variant="contained" color="error" className="ArtifactButton" onClick={handleOpen}>
        <Delete sx={{color:"white"}}/> 
        <Typography className="labelcontent">Delete</Typography>
      </SecondaryButton>
      <GeneralModalDelete
        ExternalOpen={open}
        HeaderText=""
        BodyText="Do you want to delete this artifact?"
        CloseAction={handleClose}
        PrimaryAction={deletelogic}
        CancelAction={handleClose}
      />
    </div>
  );
}
