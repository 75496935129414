import { clearStorage } from "../Helpers/GlobalData";
import {
  NotifyError,
  NotifyInfo,
  NotifySuccess,
  NotifyWarning,
} from "../Helpers/Scripts";

const UNDEFINED_RESPONSE = "No response was received";
const NO_DATA = "No data results";
const WITHOUT_RESULT = "without history version";
const INVALID_LOGIN = "Invalid username or/and password";
//const SESSION_EXPIRED = "The session has expired, please log in again";
const TIME_INVALID_SCHEDULED = "The schedule must be at least 10 minutes apart";
const DeletePack = "The requested package has been removed or is not available";
export async function getMsg(response) {
  let msg = undefined;
  if (response && response["OK"] === true) {
    msg = response["msg"];
  } else {
    setError(response);
  }

  return msg;
}
export async function getMsgDash(response, textTitleDash) {
  let msg = undefined;
  if (response && response["OK"] === true) {
    msg = response["msg"];
  } else {
    setError(response, textTitleDash);
  }

  return msg;
}

export async function getMsgResult(response) {
  let msgResult = undefined;
  if (response && response["OK"] === true) {
    if (response["msg"]["query"]) {
      msgResult = response["msg"]["result"];
    }
  } else {
    setError(response);
  }

  return msgResult;
}
export async function getMsgResultText(response) {
  let msgResult = undefined;
  if (
    response["status"] === 200 ||
    response["status"] === 201 ||
    response["status"] === 202 ||
    response["status"] === 203
  ) {
    msgResult = response;
  } else {
    setError(response);
  }

  return msgResult;
}
export async function getMsgResultIOC(response) {
  let msgResult = undefined;
  if (response && response["OK"] === true) {
    if (response["msg"]) {
      msgResult = response["msg"];
    }
  } else {
    setError(response);
  }

  return msgResult;
}
export async function getMsgResultClients(response) {
  let msgResult = undefined;
  if (response && response["OK"] === true) {
    if (response["msg"]["query"]) {
      msgResult = response["msg"]["result"];
    } else {
      NotifyError(WITHOUT_RESULT);
      msgResult = false;
    }
  } else {
    setError(response);
  }
  return msgResult;
}

export async function getResultAndShowSuccess(response, successMessage) {
  let isSuccessResponse = false;
  if (response && response["OK"] === true) {
    if (response["msg"]["query"]) {
      isSuccessResponse = response["msg"]["query"];
    } else if (response["msg"] === "unverified") {
      isSuccessResponse = false;
    } else {
      isSuccessResponse = response["msg"];
    }
  }

  if (isSuccessResponse) {
    NotifySuccess(successMessage);
  } else {
    setError(response);
  }

  return isSuccessResponse;
}

export function getResult(response) {
  let isSuccessResponse = false;
  if (response && response["OK"] === true) {
    isSuccessResponse = response["msg"]["query"];
  } else {
    setError(response);
  }
  return isSuccessResponse;
}

export function getResultAuth(response) {
  let isSuccessResponse = false;
  if (response && response["OK"] === true) {
    isSuccessResponse = response["msg"]["query"];
  } else {
    setError(response);
  }

  return { isSuccessResponse: isSuccessResponse, response: response };
}

export function getResponse(response) {
  let returnResponse = undefined;
  if (response && response["OK"] === true) {
    returnResponse = response;
  } else {
    setError(response);
  }
  return returnResponse;
}
export function getResponseText(response) {
  let returnResponse = undefined;
  if (response && response["status"] === 200) {
    returnResponse = response;
  } else {
    setError(response);
  }
  return returnResponse;
}
export function getResponseValidate(response) {
  let returnResponse = undefined;
  if (response && response["OK"] === true) {
    if (response["msg"] === "unverified") {
      setError(response);
    }
    returnResponse = response;
  } else {
    setError(response);
  }
  return returnResponse;
}

export function setError(response, textTitle = "") {
  let errorMessage = "";

  errorMessage = response ? "" : "undefinedResponse";
  let licenseError = response?.msg ? response.msg : "";
  if (errorMessage === "") {
    errorMessage =
      typeof response.msg?.query === "boolean" && response.msg?.query === false
        ? response.msg?.result
        : "";
  }

  if (errorMessage === "") {
    errorMessage = typeof response["msg"] === "string" ? response["msg"] : "";
  }

  if (errorMessage === "") {
    errorMessage =
      typeof response.msg?.msg === "string" ? response.msg?.msg : "";
  }
  switch (errorMessage) {
    case "Bad authentication":
      NotifyError(INVALID_LOGIN);
      break;

    case "Undefined or incorrect authentication email":
      NotifyError(INVALID_LOGIN);
      break;

    case "Invalid Token":
      expiredSession();
      break;

    case "undefinedResponse":
      NotifyError(UNDEFINED_RESPONSE);
      break;

    case "Without results":
      NotifyInfo(textTitle + " " + NO_DATA);
      break;

    case "The date is less than allowed":
      NotifyInfo(TIME_INVALID_SCHEDULED);
      break;

    case "The required value of id is incorrect or undefined":
      NotifyInfo(DeletePack);
      break;
    case "TOTP is wanted, add the generated value of authPayload":
      NotifyInfo("The Two Factor Activated Required Code Verify");
      break;

    case "unverified":
      NotifyError("User Not Verified Correct QR in APP");
      break;

    case "verified":
      NotifySuccess("Activation Of Two-factor Authentication Successful");
      break;

    case "Invalid request: AuthPayload is required":
      NotifyWarning("Code Verification is Required");
      break;

    case "Invalid request: AuthPayload is too short, minimum length should be 3":
      NotifyWarning(
        "Code Verification is too short, the minimum required is 3"
      );
      break;

    case "Max limit of 100 totp factors associated per entity reached":
      NotifyError(
        "Max limit 100 of request factors associated per entity reached"
      );
      break;

    case "Invalid request: AuthPayload type must be numeric":
      NotifyError("The verification code must be only numerical digits");
      break;

    case "Invalid request: AuthPayload is too long, maximum length should be 8":
      NotifyWarning(
        "Code Verification is too long, maximum length should be 8"
      );
      break;

    case "Invalid or incorrect authPayload":
      NotifyError("Invalid or Incorrect Code of Verification");
      break;

    case "The required value of language is incorrect or undefined":
      NotifyWarning("Language is Required");
      break;

    case "The required value of description is incorrect or undefined":
      NotifyWarning("Description is required");
      break;

    case "The required value of platform is incorrect or undefined":
      NotifyWarning("Platform is Required");
      break;

    case "The required value of name is incorrect or undefined":
      NotifyWarning("Name is Required");
      break;
    case "No files uploaded":
      NotifyWarning("This Code is Required");
      break;
    case "Failed to generate history":
      NotifyWarning("Failed to generate history report");
      break;
    case "No license or configuration":
      NotifyWarning("No license or configuration");
      break;
    case "Execution request not sent, client without insolation activated":
      NotifyWarning(
        "Execution request not sent, client without insolation activated"
      );
      break;
    case "Execution request not sent, client has an active execution process":
      NotifyWarning(
        "Execution request not sent, client has an active execution process"
      );
      break;
    case "The current password is incorrect":
      NotifyWarning("The current password is incorrect");
      break;

    case "Unidentified Agent":
      break;

    case "Email already registered":
      NotifyError("Can't create a new user because it's already registered");
      break;

    default:
      console.error(errorMessage);
  }
  if (licenseError !== "" && licenseError === "Expired license") {
    clearStorage();
    window.location.reload();
    sessionStorage.setItem("LicenseExpired", true);
  }
}

export function expiredSession() {
  clearStorage();
  window.location.reload();
}
