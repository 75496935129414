import { endPoint } from "./EndPoint";
import { env2 } from "../Helpers/Instance";
import { getRequest, updateRequest, downloadFile } from "./FetchRequests";
import { NotifyInfo } from "../Helpers/Scripts";
import {
  getMsgResult,
  getResultAndShowSuccess,
  getMsgResultClients,
} from "./ResponseValidation";

const AGENT_LOADED = "Agent loaded successfully";
const AGENT_UPDATED = "Agent updated successfully";
const AGENT_DELETED = "Agent deleted successfully";
const PREPARING_FILE = "Validating session to prepare your file, please wait";
const PREPARING_AGENT_FILE = "Prepare your file For upload, please wait";
const AGENT_AUTH = "Auth.json";

export async function getAgentsAsync(token) {
  const url = env2.api + endPoint.agents;
  const response = await getRequest(token, url);
  return getMsgResult(response);
}
export async function getAgentsListAsync(token) {
  const url = env2.api + endPoint.agentslist;
  const response = await getRequest(token, url);
  return getMsgResult(response);
}

export async function getOldAgentsAsync(token, ID) {
  const url = env2.api + endPoint.oldAgents + ID;
  const response = await getRequest(token, url);
  return getMsgResultClients(response);
}

export async function downloadAgentAsync(token, artifactId, name) {
  const url = env2.api + endPoint.downloadAgent + artifactId;
  NotifyInfo(PREPARING_FILE);
  await downloadFile(token, url, name);
}

export async function downloadOldAgentAsync(token, artifactId, name) {
  const url = env2.api + endPoint.downloadOldAgent + artifactId;
  NotifyInfo(PREPARING_FILE);
  await downloadFile(token, url, name);
}

export async function downloadAuthFileAsync(token, id) {
  const url = env2.api + endPoint.downloadAuth + id + "?lang=en-US";
  await downloadFile(token, url, AGENT_AUTH);
}

export async function addAgent(token, agentData) {
  const url = env2.api + endPoint.addAgent;
  let formData = new FormData();
  formData.append("file", agentData.file);
  formData.append("name", agentData.name);
  formData.append("version", agentData.version);
  formData.append("platform", agentData.platform);
  if (agentData?.id) {
    formData.append("id", agentData.id);
  }
  NotifyInfo(PREPARING_AGENT_FILE);
  const response = await updateRequest(token, url, formData);
  return getResultAndShowSuccess(response, AGENT_LOADED);
}

export async function updateAgentFile(token, agentData, ID) {
  const url = env2.api + endPoint.updateAgentFile + ID;
  let formData = new FormData();
  formData.append("file", agentData.file);
  const response = updateRequest(token, url, formData);
  return getResultAndShowSuccess(response, AGENT_UPDATED);
}

export async function updateAgent(token, agentData, ID) {
  const url = env2.api + endPoint.updateAgentFile + ID;
  let formData = new FormData();
  formData.append("file", agentData.file);
  formData.append("name", agentData.name);
  formData.append("version", agentData.version);
  const response = updateRequest(token, url, formData);
  return getResultAndShowSuccess(response, AGENT_UPDATED);
}

export async function deleteOldAgentAsync(token, agentId) {
  const url = env2.api + endPoint.deleteOldAgent + agentId;
  const response = await getRequest(token, url);
  return getResultAndShowSuccess(response, AGENT_DELETED);
}

export async function deleteAgentAsync(token, agentId) {
  const url = env2.api + endPoint.deleteAgent + agentId;
  const response = await getRequest(token, url);
  return getResultAndShowSuccess(response, AGENT_DELETED);
}
