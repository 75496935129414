import { useState } from "react";
/***************Assets from Material UI******************************/
import {
  Box,
  Grid,
  Typography,
  ThemeProvider,
  IconButton,
  Menu,
  ListItemButton,
  List,
  ListItemText,
  Divider,
  Tooltip,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";

/************Assets from component**********************************/
import "./Executions.scss";
import refreshIcon from "../../assets/images/reset-icon.svg";
import scheduledIcon from "../../assets/images/new-scheduled.svg";
import executionIcon from "../../assets/images/execution-icon-light.svg";
import ExecutionsOnDemand from "./ExecutionsOnDemand/ExecutionsOnDemand";
import ExecutionsScheduled from "./ExecutionsScheduled/ExecutionsScheduled";
import CreatedNewExecutions from "./CreatedNewExecution/CreatedNewExecutions";
import { PrimaryButton, pageTheme } from "../../Helpers/Themes";
import {
  ButtonRowsExecutions,
  ListItems,
} from "../../Helpers/StylesComponents/StylesComponents";
import ExporExcelMiddle from "../../Helpers/ExporExcelMiddle";

export default function Executions(props) {
  const { Report, tagStatus, tagExecute, AgentConnect, SelectedPage } = props;
  const [selectedIndex, setSelectedIndex] = useState(2);
  const { DataUser } = props;
  const [valueRefresh, setvalueRefresh] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const valueDate = [null, null];

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const effectRefreshTable = () => {
    setvalueRefresh((prevValue) => !prevValue);
  };

  const Execute = (value) => {
    props.Execute(value);
  };
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileId = "page-menu-mobile";
  const renderMobileMenu = (
    <Box>
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id={mobileId}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        {selectedIndex === 1 ? (
          <Box>
            <Tooltip title="Refresh all table" arrow>
              <ButtonRowsExecutions onClick={effectRefreshTable}>
                <img src={refreshIcon} alt=""></img>
              </ButtonRowsExecutions>
            </Tooltip>

            <PrimaryButton onClick={setOpenModal.bind(true)}>
              <img src={scheduledIcon} alt="" /> New emulation
            </PrimaryButton>
          </Box>
        ) : (
          <Box>
            <Tooltip title="Refresh all table" arrow>
              <ButtonRowsExecutions onClick={effectRefreshTable}>
                <img src={refreshIcon} alt=""></img>
              </ButtonRowsExecutions>
            </Tooltip>

            <ExporExcelMiddle
              pageOrigin={"Execution"}
              fileName="E.V.E. Emulations Export"
            />

            <Tooltip title="New emulation" arrow>
              <PrimaryButton
                style={{ margin: "0px !important" }}
                onClick={setOpenModal.bind(true)}
              >
                <img src={scheduledIcon} alt="" /> New emulation
              </PrimaryButton>
            </Tooltip>
          </Box>
        )}
      </Menu>
    </Box>
  );

  const CleanStatus = () => {
    props.CleanStatus();
  };

  return (
    <>
      <ThemeProvider theme={pageTheme}>
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <Typography
              variant="pageTitle"
              className="dash-top-tlt"
              sx={{ display: { xs: "none", md: "inline-block" } }}
            >
              <img
                style={{
                  width: "22px",
                  marginLeft: "5px",
                  marginRight: "10px",
                  marginTop: "10px",
                  position: "relative",
                  top: "3px",
                }}
                src={executionIcon}
                alt=""
              />
              Emulations
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <ListItems>
              <Box
                classes=""
                xs={{ width: "100%", maxWidth: 360, bgcolor: "transparent" }}
              >
                <List
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "0",
                    borderRadius: "8px",
                  }}
                  component="nav"
                  aria-label="primary"
                >
                  <ListItemButton
                    style={{
                      borderRadius: "8px 0px 0px 8px",

                      border: "1px solid #464366",
                      textAlign: "center",
                    }}
                    selected={selectedIndex === 2}
                    onClick={(event) => handleListItemClick(event, 2)}
                  >
                    <ListItemText primary="On Demand" />
                  </ListItemButton>
                  <Divider />

                  <ListItemButton
                    style={{
                      borderRadius: "0px 8px 8px 0px",
                      border: "1px solid #464366",
                      textAlign: "center",
                    }}
                    selected={selectedIndex === 1}
                    onClick={(event) => handleListItemClick(event, 1)}
                  >
                    <ListItemText primary="Scheduled" />
                  </ListItemButton>
                </List>
              </Box>
            </ListItems>
          </Grid>

          <Grid item xs={1}>
            <Box style={{ display: "flex", float: "right" }}></Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                float: { xs: "none", md: "right" },
                display: { xs: "none", md: "inline-block" },
              }}
            >
              {selectedIndex === 1 ? (
                <>
                  <Tooltip title="Refresh all table" arrow>
                    <ButtonRowsExecutions onClick={effectRefreshTable}>
                      <img src={refreshIcon} alt="" />
                    </ButtonRowsExecutions>
                  </Tooltip>

                  <PrimaryButton onClick={setOpenModal.bind(true)}>
                    <img src={scheduledIcon} alt="" /> New emulation
                  </PrimaryButton>
                </>
              ) : (
                <>
                  <ExporExcelMiddle
                    pageOrigin={"Execution"}
                    fileName="E.V.E. Emulations Export"
                  />

                  <Tooltip title="New emulation" arrow>
                    <PrimaryButton
                      style={{ margin: "0px !important" }}
                      onClick={setOpenModal.bind(true)}
                    >
                      <img src={scheduledIcon} alt="" /> New emulation
                    </PrimaryButton>
                  </Tooltip>
                </>
              )}
            </Box>
            <Box
              sx={{
                float: { xs: "right", md: "none" },
                display: { xs: "inline-block", md: "none" },
              }}
            >
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreVert />
              </IconButton>
            </Box>
          </Grid>

          {renderMobileMenu}

          <Grid item xs={12}>
            {selectedIndex === 1 ? (
              <ExecutionsScheduled
                effectRefresh1={valueRefresh}
                Token={DataUser}
              >
                Scheduled
              </ExecutionsScheduled>
            ) : (
              <ExecutionsOnDemand
                SelectedPage={SelectedPage}
                DataUser={DataUser}
                Report={Report}
                Execute={Execute}
                refrestTable={valueRefresh}
                HandleDataDate={valueDate}
                tagStatus={tagStatus}
                tagExecute={tagExecute}
                AgentConnect={AgentConnect}
                CleanStatus={CleanStatus}
              >
                Ondemand
              </ExecutionsOnDemand>
            )}
          </Grid>
        </Grid>

        <CreatedNewExecutions
          openModalGet={openModal}
          DataUser={DataUser}
          AgentConnect={AgentConnect}
          Execute={Execute}
          effectRefresh={effectRefreshTable}
        ></CreatedNewExecutions>
      </ThemeProvider>
    </>
  );
}
