/**
 * Realiza una solicitud HTTP utilizando Axios.
 *
 * @param {string} method - El método HTTP a utilizar (GET, POST, PUT, DELETE).
 * @param {string} url - La URL de la solicitud.
 * @param {object} source - El objeto que contiene el token de cancelación.
 * @param {array} [body] - El cuerpo de la solicitud (opcional).
 * @returns {Promise<any>} La respuesta de la solicitud.
 */

import axios from "axios";

export default async function AxiosFetch(method, url, source, body) {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Access-Control-Allow-Origin": window.location.origin,
      },
      cancelToken: source.token,
    };
    if (method === "POST") {
      config.headers["Content-Type"] = "application/json";
    }

    let response;
    switch (method) {
      case "GET":
        response = await axios.get(url, config);
        break;
      case "POST":
        response = await axios.post(url, body, config);
        break;
      case "PUT":
        response = await axios.put(url, body, config);
        break;
      case "DELETE":
        response = await axios.delete(url, config);
        break;
      default:
        throw new Error(`Método no soportado: ${method}`);
    }
    if (localStorage.getItem("logview"))
      console.log(`Respuesta de ${url}:`, response.data);
    return response.data;
  } catch (err) {
    if (axios.isCancel(err)) {
      console.log(`successfully aborted ${url}`);
    } else {
      // handle error
    }
  }
}

AxiosFetch.defaultProps = {
  method: "GET",
  url: "",
  source: null,
  body: null,
};
