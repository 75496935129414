import {
  Box,
  IconButton,
  InputAdornment,
  MenuItem,
  Modal,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { ActionButton,SelectCustom ,FormInputTextField} from "../../../Helpers/Themes";
import detailsIcon from "../../../assets/images/eye-icon.svg";
import { UpdateEnviromentToken } from "../../../Services/Enviroment";

export default function ModelToken(props) {
  const { DataSettings, DataUser } = props;
  const [open, setOpen] = useState(false);
  const [ACTIVE, setACTIVE] = useState("");
  const [TIME, setTIME] = useState("");


  const handleOpen = () => {
    setACTIVE(DataSettings[0].value);
    let integer=parseInt(DataSettings[1].value.split("h")[0])
    setTIME(integer);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const putUpdateData = async () => {
    const data = {
      "ACTIVE": ACTIVE,
      "TIME": TIME+"h",
      "permanent": "Y"
    }
    await UpdateEnviromentToken(DataUser.token, data);
    props.Reload();
  };

  const body = (
    <div
      className="Section"
      style={{
        backgroundColor: "#090f1a",
        borderBottom: "1px solid #1e1c3a",
        width: "400px",
      }}
    >
      <Typography align="center" variant="h6">
        Token parameters
      </Typography>
      <Box
        style={{
          width: "100%",
          backgroundColor: "rgb(36,41,51)",
          border: "1px solid #1e1c3a",
          padding: "15px",
          boxShadow: "0px 4px 4px rgb(0 0 0 / 4%)",
          borderRadius: "12px",
          marginTop: "24px",
          textAlign:"center",
          
        }}
      >
        <Typography>Time Until Token Expiration</Typography>
        <FormInputTextField
          style={{ margin: "7px" }}
          type="number"
          onKeyDown={(e) => {
            if (e.key === ".") {
              e.preventDefault();
            }
         }}
          value={TIME}
          onChange={(event) => {
            if(event.target.value !== "."){
              setTIME(event.target.value); 
            }                         
          }}
          
          InputProps={{ inputProps: { min: 1, max: 172800000 }, endAdornment: <InputAdornment position="end">hr</InputAdornment>, }}
        />
        <Typography>
        Enable connection security token
        </Typography>
        <SelectCustom
          fullWidth
          style={{ margin: "7px" }}
          value={ACTIVE}
          variant="outlined"
          onChange={(event) => {
            if(event.target.value !== " "){
              setACTIVE(event.target.value);
            }
          }}
        >
          <MenuItem value="0" disabled>Select value</MenuItem>
          <MenuItem value="Y">Yes</MenuItem>
          <MenuItem value="n">No</MenuItem>
        </SelectCustom>
      </Box>
      <ActionButton
        onClick={putUpdateData}
        disabled={TIME === "" || parseInt(TIME) < 1}
        style={{ width: "70%", marginLeft: "15%" }}
      >
        Update
      </ActionButton>
    </div>
  );

  return (
    <div>
      <IconButton onClick={handleOpen}>
        <img src={detailsIcon} alt="" />
      </IconButton>

      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
    </div>
  );
}
