import { useCallback, useEffect, useMemo, useState } from "react";
import Menu from "./MenuV3";
import { getStorageData } from "../../Helpers/GlobalData";
import Authentication from "../Login/Authentication/Authentication";
import Logo from "../../assets/logo.png";
import Cookies from "universal-cookie";
import axios from "axios";
import { endPoint } from "../../Services/EndPoint";
import { env2 } from "../../Helpers/Instance";
import AxiosFetch from "../../Services/FetchServices";
import { Box, CircularProgress, Paper, Typography } from "@mui/material";
import { CheckCircleRounded } from "@mui/icons-material";
const stylePaper = {
  m: 1,
  width: "100%",
  height: "auto",
  textAlign: "center",
  alignContent: "center",
  backgroundColor: "transparent",
  boxShadow: "none",
};
const styleFont = {
  fontSize: "30px",
  color: "#fff",
  textShadow: "1px 1px 1px #000",
};
export function Home() {
  const [msg, setmsg] = useState(null);
  const [process, setProcess] = useState(false);
  const cookies = useMemo(() => new Cookies(), []);
  const [msgJson, setMsgJson] = useState([]);

  const setMsgState = useCallback(() => {
    setProcess(false);
    let storageData = getStorageData(); // Wrap in useMemo with an empty dependency array
    cookies.set("CurrentPageDataPagination", "home", {
      path: "/",
      sameSite: "none",
      secure: true,
    });
    if (storageData.msg !== null) {
      setmsg(storageData.msg);
      let Msg = JSON.parse(storageData.msg);
      setMsgJson(Msg);
      localStorage.setItem("token", Msg.token);
    }
    setProcess(true);
    let socketlogs = localStorage.getItem("viewSocketLogs");
    if (!socketlogs) {
      localStorage.setItem("viewSocketLogs", false);
    }
  }, [cookies]);

  const handleCloseSession = () => {
    setmsg(null);
    localStorage.removeItem("token-remember-me");
  };

  useEffect(() => {
    setMsgState();
    return () => {};
  }, [setMsgState]);

  const [getInfo, setGetInfo] = useState(false);

  const [phaseAttacks, setphaseAttacks] = useState(false);
  const [threatTypes, setthreatTypes] = useState(false);
  const [packageTypes, setpackageTypes] = useState(false);

  useEffect(() => {
    if (msgJson?.token) {
      let phaseAttacks = localStorage.getItem("phaseAttacks");
      let threatTypes = localStorage.getItem("threatTypes");
      let packageTypes = localStorage.getItem("packageTypes");

      if (!phaseAttacks || !threatTypes || !packageTypes) {
        setGetInfo(true);

        if (!phaseAttacks) {
          setphaseAttacks(true);
        }

        if (!threatTypes) {
          setthreatTypes(true);
        }

        if (!packageTypes) {
          setpackageTypes(true);
        }
      }
    }

    return () => {};
  }, [msgJson]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    async function GetData() {
      if (phaseAttacks) {
        const url = env2.api + endPoint.phaseAttacks;
        const data = await AxiosFetch("GET", url, source);
        if (data?.msg && data.msg.query) {
          let result = data.msg.result;
          localStorage.setItem("phaseAttacks", JSON.stringify(result));
          setphaseAttacks(false);
        }
      }
    }
    GetData();
    return () => {
      source.cancel();
    };
  }, [phaseAttacks]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    async function GetData() {
      if (threatTypes) {
        const url = env2.api + endPoint.threatTypes;
        const data = await AxiosFetch("GET", url, source);
        if (data?.msg && data.msg.query) {
          let result = data.msg.result;
          localStorage.setItem("threatTypes", JSON.stringify(result));
          setthreatTypes(false);
        }
      }
    }
    GetData();
    return () => {
      source.cancel();
    };
  }, [threatTypes]);
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    async function GetData() {
      if (packageTypes) {
        const url = env2.api + endPoint.packageTypes;
        const data = await AxiosFetch("GET", url, source);
        if (data?.msg && data.msg.query) {
          let result = data.msg.result;

          localStorage.setItem("packageTypes", JSON.stringify(result));
          setpackageTypes(false);
        }
      }
    }
    GetData();
    return () => {
      source.cancel();
    };
  }, [packageTypes]);

  useEffect(() => {
    if (getInfo) {
      if (!phaseAttacks && !threatTypes && !packageTypes) {
        setTimeout(() => {
          setGetInfo(false);
        }, 1000);
      }
    }
    return () => {};
  }, [phaseAttacks, threatTypes, packageTypes, getInfo]);

  return (
    <>
      {getInfo ? (
        <Box className="container-login">
          <>
            <img
              src={Logo}
              alt="logo-EVE"
              style={{ width: "600px", height: "100px", margin: "40px 20px" }}
            />
          </>
          <Box
            className={"Section"}
            sx={{
              backgroundImage: "url(../../assets/background3.jpg)",
              backgroundColor: "#0a081866",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Paper sx={stylePaper} elevation={4}>
                <Typography sx={styleFont}>
                  {phaseAttacks ? (
                    <CircularProgress sx={{ color: "#c59d48" }} />
                  ) : (
                    <CheckCircleRounded
                      sx={{ color: "#c59d48", fontSize: "30px" }}
                    />
                  )}{" "}
                  {"Getting catalog of attack phases ..."}
                </Typography>
              </Paper>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Paper sx={stylePaper} elevation={4}>
                <Typography sx={styleFont}>
                  {threatTypes ? (
                    <CircularProgress sx={{ color: "#c59d48" }} />
                  ) : (
                    <CheckCircleRounded
                      sx={{ color: "#c59d48", fontSize: "30px" }}
                    />
                  )}{" "}
                  {"Getting catalog of threat types ..."}
                </Typography>
              </Paper>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Paper sx={stylePaper} elevation={4}>
                <Typography sx={styleFont}>
                  {packageTypes ? (
                    <CircularProgress sx={{ color: "#c59d48" }} />
                  ) : (
                    <CheckCircleRounded
                      sx={{ color: "#c59d48", fontSize: "30px" }}
                    />
                  )}{" "}
                  {"Getting catalog of package types ..."}
                </Typography>
              </Paper>
            </Box>
          </Box>
        </Box>
      ) : !process ? (
        <div></div>
      ) : msg !== null ? (
        <div className="Center">
          <Menu
            name={msgJson.user.result.userName}
            passLogin={msgJson.token}
            DataUser={msgJson}
            closeSession={handleCloseSession}
          />
        </div>
      ) : (
        <Authentication
          closeSession={handleCloseSession}
          OnOkLogin={setMsgState}
        />
      )}
    </>
  );
}
