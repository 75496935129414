import React from "react";
import IconButton from "@mui/material/IconButton";
import {
  Tooltip,
} from "@mui/material";
import "../AgentStyles.scss";

import { Box } from "@mui/system";
import { deleteClient } from "../../../Services/Clients";
import { NotifyInfo } from "../../../Helpers/Scripts";
import GeneralModalDelete from "../../../Helpers/CommonComponents/GeneralDeleteModal";
import { Delete } from "@mui/icons-material";

export default function Deletemodal(props) {
  const { row, DataUser } = props;
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    if(row.online){
      NotifyInfo("Unable to delete an online Endpoint");
    }
    else{
    setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const deletelogic = async() => {
    const response = await deleteClient(DataUser.token, row.id);
      if(response){
        props.Reload()
        handleClose();
  };}
  return (
    <Box style={{ display: "inline-block" }}>
      <Tooltip title="Delete" >
        <IconButton className="iconButtonSize">
        <Delete color="inherit" onClick={handleOpen} /> 
        </IconButton>
      </Tooltip>
      <GeneralModalDelete
        ExternalOpen={open}
        HeaderText=""
        BodyText="Do you want to delete this endpoint?"
        CloseAction={handleClose}
        PrimaryAction={deletelogic}
        CancelAction={handleClose}
      />
    </Box>
  );
}