import { DesktopMacTwoTone } from "@mui/icons-material";

import {
  Box,
  Checkbox,
  TablePagination,
  Tooltip,
  Typography,
} from "@mui/material";
import { GridOverlay } from "@mui/x-data-grid-pro";
import { useCallback, useEffect, useState } from "react";
import {
  SecondaryButton,
  StyledDataGrid,
  CustomLoadingOverlay,
  QuickSearchToolbar,
  PrimaryButton,
} from "../../../../../../../Helpers/Themes";
import { getClientsAsync } from "../../../../../../../Services/Clients";
import ConnexionIcon from "../../../../../../../assets/images/connected-icon.svg";
import DesconnexionIcon from "../../../../../../../assets/images/not-connected-icon.svg";
import WindowIcon from "../../../../../../../assets/images/window-icon.svg";
import LinuxIcon from "../../../../../../../assets/images/linux-icon.svg";
import MacIcon from "../../../../../../../assets/images/apple-logo.svg";
import UnkwonIcon from "../../../../../../../assets/images/device-unknown-icon.svg";
import { NotifyError } from "../../../../../../../Helpers/Scripts";

export default function StepAgentScript(props) {
  const { DataUser, valueSelectAgent, AgentConnect } = props;
  const [loading, setLoading] = useState(false);
  const [tempRowsForAllAgentsClients, settempRowsForAllAgentsClients] =
    useState([]);
  const [countAllAgents, setCountAllAgents] = useState(0);
  const [totalRows, settotalRows] = useState([]);
  const [agentSelection, setAgentSelection] = useState(valueSelectAgent || []);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sortModel, setSortModel] = useState([
    { field: "online", sort: "desc" },
  ]);
  const [page, setPage] = useState(0);

  const handleSortModelChange = (newModel) => {
    setSortModel(newModel);
  };
  const [searchText, setSearchText] = useState("");

  const newGetAllAgentsClients = useCallback(async () => {
    setLoading(true);
    const response = await getClientsAsync(DataUser.token);
    if (response) {
      let array = [];
      response.forEach((element) => {
        array.push({
          id: element._id,
          online: element.online,
          hostname: element?.hostname
            ? element.hostname
            : element.workUser[0].hostname,
          ip:
            element.IPInformation.length > 0
              ? element.IPInformation[0].public.IP
              : "N/A",
          private:
            element.IPInformation.length > 0
              ? element.IPInformation[0].private.IP
              : "N/A",
          port:
            element.IPInformation.length > 0
              ? element.IPInformation[0].port
              : "N/A",
          osInformation: element.osInformation[0].name,
          selection: false,
          platform: element.osInformation[0].platform,
        });
      });
      // console.log(array);
      array.forEach((element) => {
        agentSelection.forEach((element2) => {
          if (element.id === element2.id) {
            element.selection = element2.selection;
          }
        });
      });
      setCountAllAgents(array.length);
      settempRowsForAllAgentsClients(array);
      settotalRows(array);
      setLoading(false);
    } else {
      settempRowsForAllAgentsClients([]);
      settotalRows([]);
    }
  }, [DataUser.token, agentSelection]);

  useEffect(() => {
    newGetAllAgentsClients();
  }, [AgentConnect, newGetAllAgentsClients]);

  const handleToggleAgents = (value) => () => {
    const currentIndex = findWithAttr(agentSelection, "id", value.id);
    const newChecked = [...agentSelection];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setAgentSelection(newChecked);
    if (agentSelection.length >= 30) {
      NotifyError("Sorry to exceed limit of agents selected is 30.");
    }
  };

  function findWithAttr(array, attr, value) {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }
  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.2,
      filterable: false,
      sortable: false,
      minWidth: 40,
      align: "center",
      renderHeader: () => <Box style={{ color: "#0a0818" }}>|</Box>,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <Checkbox
          style={{ padding: 10 }}
          onClick={handleToggleAgents({
            id: params.row.id,
            hostname: params.row.hostname,
            platform: params.row.platform,
          })}
          checked={findWithAttr(agentSelection, "id", params.value) !== -1}
        ></Checkbox>
      ),
    },
    {
      field: "online",
      headerName: "Online",
      flex: 0.2,
      minWidth: 50,
      align: "center",
      type: "boolean",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>
          <DesktopMacTwoTone style={{ verticalAlign: "middle" }} />
        </Box>
      ),
      renderCell: (params) => (
        <>
          {params.value ? (
            <Box>
              <Tooltip title="Connected">
                <img
                  src={ConnexionIcon}
                  alt=""
                  style={{ marginRight: "15px" }}
                />
              </Tooltip>
            </Box>
          ) : (
            <Box>
              <Tooltip title="Disconnected">
                <img
                  src={DesconnexionIcon}
                  alt=""
                  style={{ marginRight: "15px" }}
                />
              </Tooltip>
            </Box>
          )}
        </>
      ),
    },
    {
      field: "platform",
      headerName: "Platform",
      flex: 0.2,
      minWidth: 50,
      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%", fontWeight: "600" }}>
          Platform
        </Box>
      ),
      renderCell: (params) => (
        <>
          {params.row.platform === "Windows" ? (
            <img src={WindowIcon} alt="" style={{ marginRight: "7px" }} />
          ) : params.row.platform === "GNULinux" ? (
            <img src={LinuxIcon} alt="" style={{ marginRight: "7px" }} />
          ) : params.row.platform === "Mac" ? (
            <img src={MacIcon} alt="" style={{ marginRight: "7px" }} />
          ) : (
            <img src={UnkwonIcon} alt="" style={{ marginRight: "7px" }} />
          )}
        </>
      ),
    },
    {
      field: "hostname",
      headerName: "Host name",
      flex: 1,
      minWidth: 200,

      renderCell: (params) => (
        <>
          <Typography
            style={{
              color: params.row.online ? "green" : "rgba(255,255,255,0.65)",
            }}
          >
            {params.value}
          </Typography>
        </>
      ),
    },
  ];
  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    let filteredRows = [];
    totalRows?.forEach((row) => {
      if (row.hostname.toUpperCase().indexOf(searchValue.toUpperCase()) > -1) {
        filteredRows.push(row);
      }
    });
    settempRowsForAllAgentsClients(filteredRows);
    let NoTotal = filteredRows.length;
    setCountAllAgents(NoTotal);
    if (searchValue === "") {
      settempRowsForAllAgentsClients(totalRows);
      let NoTotal = totalRows.length;
      setCountAllAgents(NoTotal);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "rgb(21, 19, 43)",
          padding: "20px",
          borderRadius: "10px",
          width: "100%",
          height: "auto",
          textAlign: "left",
          overflow: "auto",
          maxHeight: "600px",
        }}
      >
        <Typography textAlign={"center"}>Select Endpoints:</Typography>
        <StyledDataGrid
          components={{
            Toolbar: QuickSearchToolbar,
            LoadingOverlay: CustomLoadingOverlay,
            NoRowsOverlay: () => (
              <GridOverlay>
                <div>No Data Results</div>
              </GridOverlay>
            ),
            NoResultsOverlay: () => (
              <GridOverlay>
                <div>No Results Filter Data</div>
              </GridOverlay>
            ),
            Pagination: () => (
              <>
                <TablePagination
                  component="div"
                  page={page}
                  count={countAllAgents}
                  onPageChange={handleChangePage}
                  rowsPerPageOptions={[5, 10, 20]}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  showFirstButton
                  showLastButton
                />
              </>
            ),
          }}
          rows={tempRowsForAllAgentsClients}
          columns={columns}
          pagination
          pageSize={rowsPerPage}
          rowsPerPageOptions={[5, 10, 20]}
          rowCount={countAllAgents}
          page={page}
          sortModel={sortModel}
          onSortModelChange={(newSort) => handleSortModelChange(newSort)}
          onPageChange={handleChangePage}
          onPageSizeChange={(PerPage) => setRowsPerPage(PerPage)}
          disableSelectionOnClick
          loading={loading}
          density="comfortable"
          autoHeight
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: (event) => requestSearch(event.target.value),
              clearSearch: () => requestSearch(""),
            },
          }}
        />
      </Box>
      <SecondaryButton
        color="inherit"
        // disabled={activeStep === 0}
        onClick={() => {
          props.HandleAgentSelection(agentSelection);
          props.HandleActiveStepBack();
        }}
        sx={{ mr: 1 }}
      >
        Back
      </SecondaryButton>
      <PrimaryButton
        disabled={agentSelection.length > 0 ? false : true}
        style={{ float: "right" }}
        onClick={() => {
          props.HandleAgentSelection(agentSelection);
          props.HandleActiveStepNext();
        }}
      >
        Next
      </PrimaryButton>
    </>
  );
}
