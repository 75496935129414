import { env2 } from "../../../Helpers/Instance";
import { endPoint } from "../../../Services/EndPoint";
import { getResultAndShowSuccess, getMsgResult, getResponse } from "../../../Services/ResponseValidation";
import { getRequest, postRequest, postAppJsonRequestWithoutToken, postAppJsonRequest } from "../../../Services/FetchRequests";

const USER_UPDATED     = "User updated successfully";
const USER_CREATED     = "User created successfully";
const USER_DELETED     = "User deleted successfully";
const LOCKED_ACCOUNT   = "Account lock";
const UNLOCK_ACCOUNT   = "Account active";
const PASSWORD_UPDATED = "Password updated successfuly";

export async function addUserAsync(token, userData) {
  const url = env2.api + endPoint.addUser;
  let formdata = new FormData();
  // formdata.append("userName", userData.userName);
  formdata.append("firstName", userData.firstName);
  formdata.append("lastName", userData.lastName);
  formdata.append("rol", userData.rol);
  formdata.append("email", userData.email);
  formdata.append("password", userData.password);
  formdata.append("repassword", userData.repassword);
  formdata.append("ico", userData.icon);

  const response = await postRequest(token, url, formdata);
  const resultOk = getResultAndShowSuccess(response, USER_CREATED);
  return resultOk;
}

export async function getUserAsync(token, id) {
  const url = env2.api + endPoint.getuser + id;
  const response = await getRequest(token, url);
  return getMsgResult(response);
}

export async function getUsers(token) {
  const url = env2.api + endPoint.users;
  const response = await getRequest(token, url);
  return getMsgResult(response);
}

export async function updatePassword(token, userData) {
  const url = env2.api + endPoint.updateUser + userData.id;
  let formdata = new FormData();
  formdata.append("oldPassword", userData.oldPassword);
  formdata.append("password", userData.password);
  formdata.append("repassword", userData.repassword);
  // formdata.append("rol", userData.rol);

  const response = await postRequest(token, url, formdata);
  return getResultAndShowSuccess(response, PASSWORD_UPDATED);
}

export async function updateUser(token, userData, id) {
  const url = env2.api + endPoint.updateUser + id;

  let formdata = new FormData();
  if (userData.userName) {
    formdata.append("userName", userData.userName);
  }
  if (userData.firstName) {
    formdata.append("firstName", userData.firstName);
  }
  if (userData.lastName) {
    formdata.append("lastName", userData.lastName);
  }
  if (userData.rol) {
    formdata.append("rol", userData.rol);
  }
  if (userData.email) {
    formdata.append("email", userData.email);
  }
  formdata.append("ico", userData.icon);

  const response = await postRequest(token, url, formdata);
  return getResultAndShowSuccess(response, USER_UPDATED);
}

export async function getLoggin(logginData) {
  const url = env2.api + endPoint.loggin;
  const response = await postAppJsonRequestWithoutToken(url, JSON.stringify(logginData));
  return getResponse(response);
}

export async function lockPassword(email) {
  const url = env2.api + endPoint.lockPassword;
  const body = JSON.stringify({ email: email });
  const response = await postAppJsonRequestWithoutToken(url, body);  
  return getResultAndShowSuccess(response, LOCKED_ACCOUNT);
}

export async function unlockPassword(bodyReques) {
  const url = env2.api + endPoint.unlockPassword;
  const body = JSON.stringify(bodyReques);
  const response = await postAppJsonRequestWithoutToken(url, body);
  return getResultAndShowSuccess(response, UNLOCK_ACCOUNT);
}

export async function deleteUser(token, userId) {
  const url = env2.api + endPoint.deleteUser + userId;
  const response = await getRequest(token, url);
  return getResultAndShowSuccess(response, USER_DELETED);
}

export async function postQRValidation(token,dataGet, msgOptional) {
  let data = dataGet;
  const url = env2.api + endPoint.userTOTP;
  const body = JSON.stringify(data);
  const response = await postAppJsonRequest(token, url, body);
  return getResultAndShowSuccess(response, msgOptional === undefined ? "Get QR Success" : msgOptional);
}

export async function postValidationTOTP(token,dataGet,msgOptional) {
  let data = dataGet;
  const url = env2.api + endPoint.userVerifyTOTP;
  const body = JSON.stringify(data);
  const response = await postAppJsonRequest(token, url, body);
  return getResultAndShowSuccess(response, msgOptional === undefined ? "Get QR Success" : msgOptional);
}
