import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Cookies from "universal-cookie";

export default function NetworkSecurityEvation(props) {
  const { Data } = props;
  const [dataTree, setdataTree] = useState(Data);
  const cookies = useMemo(() => new Cookies(), []); // Wrap in useMemo with an empty dependency array

  useEffect(() => {
    const obj = {
      FilterName: "",
      FilterProcesState: "",
      FilterVector: "",
      FilterStatus: "",
      valueDate: [null, null],
    };
    cookies.set("DataFiltersPagination", JSON.stringify(obj), {  path: "/",
  sameSite: "none",
  secure: true,});
    const defaultDataPAgination = { page: 0, rowsPerPage: 10 };
    cookies.set("DataPagination", JSON.stringify(defaultDataPAgination), {
      path: "/",
    });
    setdataTree(Data);
  }, [Data, dataTree, cookies]);

  return (
    <Box
      style={{
        minHeight: "200px",
        flex: "0 0 50%",
        maxWidth: "100%",
      }}
    >
      <Box style={{ marginRight: "0!important", height: "100%!important" }}>
        <Box
          style={{
            paddingRight: "0!important",
            marginbottom: "1rem!important",
            flex: "0 0 100%",
            maxWidth: "100%",
          }}
        >
          <Box
            style={{
              backgroundColor: "#0a0818",
              borderRadius: "14.4483px",
              padding: "15px",
              height: "100%",
            }}
          >
            <Typography>Network Security Evasions</Typography>
            <Box
              style={{ width: "100%", height: "100%", fontSize: "14px" }}
              id="NetworkSecurityEvation"
            ></Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
