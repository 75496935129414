import { endPoint } from "./EndPoint";
import { env2 } from "../Helpers/Instance";
import { postAppJsonRequest, getRequest, postRequest } from "./FetchRequests";
import { getMsgResult, getResultAndShowSuccess } from "./ResponseValidation";

const EXECUTION_UPDATED = "Emulation updated successfully";
const EXECUTION_DELETED = "Emulation deleted successfully";
// const EXECUTION_CREATED = "Execution created successfully";

export async function GetExecutionsAsymc(token) 
{
    const url = env2.api + endPoint.executions;
    const response = await getRequest(token, url);
    const result = getMsgResult(response);
    return result;
}

export async function GetScheduledExecutionsAsync(token)
{
    const url = env2.api + endPoint.scheduledExecutions;
    const response = await getRequest(token, url);
    const result = getMsgResult(response);
    return result;
}

export async function AddExecutionAsync(token, data)
{
    const url = env2.api + endPoint.addExecution;
    const body = JSON.stringify(data);
    const response = await postAppJsonRequest(token, url, body);
    const result = getMsgResult(response);
    return result;
}

export async function GetExecutionsCount(token)
{
    const url = env2.api + endPoint.executionsCount;
    const response = await getRequest(token, url);
    const result = getMsgResult(response);
    return result;
}

export async function GetAdvancedExecutionsPage(token, filterData, bodyContent)
{
    const url = env2.api + endPoint.getAdvancedExecutionPage + filterData.size + "/" + filterData.pageNumber + "?col=" + filterData.filter + "&order=" + filterData.order;
    const body = JSON.stringify(bodyContent);
    const response = await postAppJsonRequest(token, url, body)
    const result = getMsgResult(response);
    return result;
}

export async function GetMinExecutionPageAsync(token, filterData)
{
    const url = env2.api + endPoint.getMinExecutionPage + filterData.size + "/" + filterData.pageNumber + "?col=" + filterData.filter + "&order=" + filterData.order;
    const response = await postRequest(token, url)
    const result = getMsgResult(response);
    return result;
}

export async function UpdateExecutionAsync(token, data)
{
    const url = env2.api + endPoint.updateExecution + data.id;
    const body = JSON.stringify(data);
    const response = await postAppJsonRequest(token, url, body);
    const result = getResultAndShowSuccess(response, EXECUTION_UPDATED);
    return result;
}

export async function DesactivateExecutionAsync(token, id)
{
    const url = env2.api + endPoint.desactivateExecution + id;
    const response = await getRequest(token, url);
    const result = getResultAndShowSuccess(response, EXECUTION_DELETED);
    return result;
}