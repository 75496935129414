import { Box, ThemeProvider } from "@mui/material";
import { pageTheme } from "../../Helpers/Themes";
import HeaderScriptPage from "./Sections/HeaderScriptPage/HeaderScriptPage";
import BodyScriptPage from "./Sections/BodyScriptPage/BodyScriptPage";
import { useEffect, useMemo, useState } from "react";
import Cookies from "universal-cookie";

export default function ScriptPage(props) {
  const { DataUser, ReloadAgents, AgentConnect } = props;
  const [selectIndex, setselectIndex] = useState(1);
  const [valueReload, setvalueReload] = useState(false);
  const [Reloads, setReloads] = useState(0);
  const cookies = useMemo(() => new Cookies(), []); // Wrap in useMemo with an empty dependency array

  useEffect(() => {
    const obj = {
      FilterName: "",
      FilterProcesState: "",
      FilterVector: "",
      FilterStatus: "",
      valueDate: [null, null],
    };
    cookies.set("DataFiltersPagination", JSON.stringify(obj), {  path: "/",
  sameSite: "none",
  secure: true,});
    const defaultDataPAgination = { page: 0, rowsPerPage: 10 };
    cookies.set("DataPagination", JSON.stringify(defaultDataPAgination), {
      path: "/",
    });
    if (ReloadAgents !== 0 && ReloadAgents !== undefined) {
      setReloads(ReloadAgents);
    }
  }, [ReloadAgents, cookies]);

  const handleListItemClickHeader = (index) => {
    setselectIndex(index);
  };

  const HandleReload = (value) => {
    setvalueReload((prevValue) => !prevValue);
  };

  const Execute = (value) => {
    props.Execute(value);
  };

  return (
    <Box style={{ width: "100%" }}>
      <ThemeProvider theme={pageTheme}>
        <HeaderScriptPage
          handleListItemClickMain={handleListItemClickHeader}
          DataUser={DataUser}
          HandleReload={HandleReload}
          ReloadAgents={Reloads}
        />

        <BodyScriptPage
          handleselectListItemClickBody={selectIndex}
          DataUser={DataUser}
          HandleReload={valueReload}
          Execute={Execute}
          AgentConnect={AgentConnect}
        />
      </ThemeProvider>
    </Box>
  );
}
