import { endPoint } from "./EndPoint";
import { env2 } from "../Helpers/Instance";
import { getRequest, updateJsonRequest, postAppJsonRequest } from "./FetchRequests";
import { getMsgResult, getResultAndShowSuccess } from "./ResponseValidation";

const EXECUTION_ENVIROMENT_UPDATED = "Execution enviroment updated successfully";
const ISOLATION_IPS_UPDATED = "Policies updated correctly";
const TOKEN_UPDATED = "Token updated successfully";
const EMAIL_UPDATED = "Email updated successfully";
const URL_UPDATED = "C2 Url updated successfully";
const EMAIL_TESTED = "Check your email";

export async function GetEnviromentAsymc(token) 
{
    const url = env2.api + endPoint.enviroments;
    const response = await getRequest(token, url);
    const result = getMsgResult(response);
    return result;
}
export async function GetIsolationIPDataAsymc(token) 
{
    const url = env2.api + endPoint.isolationIp;
    const response = await getRequest(token, url);
    const result = getMsgResult(response);
    return result;
}

export async function UpdateExecutionEnviromentAsync(token, bodyData)
{
    const url = env2.api + endPoint.updateExecutionEnviroment;
    const jsonBody = JSON.stringify(bodyData);
    const response = await updateJsonRequest(token, url, jsonBody);
    const result = getResultAndShowSuccess(response, EXECUTION_ENVIROMENT_UPDATED);
    return result;
}

export async function UpdateIps(token, bodyData)
{
    const url = env2.api + endPoint.updateIsolationEnviroment;
    const jsonBody = JSON.stringify(bodyData);
    const response = await postAppJsonRequest(token, url, jsonBody);
    const result = getResultAndShowSuccess(response, ISOLATION_IPS_UPDATED);
    return result;
}
export async function DeleteGroupIp(token, bodyData)
{
    const url = env2.api + endPoint.updateIsolationEnviroment;
    const jsonBody = JSON.stringify(bodyData);
    const response = await postAppJsonRequest(token, url, jsonBody);
    const result = getMsgResult(response);
    return result;
}
export async function UpdateEnviromentToken(token, bodyData)
{
    const url = env2.api + endPoint.updateTokenEnviroment;
    const jsonBody = JSON.stringify(bodyData);
    const response = await updateJsonRequest(token, url, jsonBody);
    const result = getResultAndShowSuccess(response, TOKEN_UPDATED);
    return result;
}

export async function UpdateEmailEnviroment(token, bodyData)
{
    const url = env2.api + endPoint.updateEmailEnviroment;
    const jsonBody = JSON.stringify(bodyData);
    const response = await updateJsonRequest(token, url, jsonBody);
    const result = getResultAndShowSuccess(response, EMAIL_UPDATED);
    return result;
}

export async function UpdateUrlEnviroment(token, bodyData)
{
    const url = env2.api + endPoint.updateUrlEnviroment;
    const jsonBody = JSON.stringify(bodyData);
    const response = await updateJsonRequest(token, url, jsonBody);
    const result = getResultAndShowSuccess(response, URL_UPDATED);
    return result;
}

export async function TestEnviromentEmail(token, data)
{
    const url = env2.api + endPoint.testEmailEnviroment;
    const jsonBody = JSON.stringify(data);
    const response = await postAppJsonRequest(token, url, jsonBody);
    const result = getResultAndShowSuccess(response, EMAIL_TESTED);
    return result;
}