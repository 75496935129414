import { endPoint } from "./EndPoint";
import { env2 } from "../Helpers/Instance";
import { postAppJsonRequest, getRequest, postRequest, updateRequest, downloadFile } from "./FetchRequests";
import { getResultAndShowSuccess, getMsgResult, getResult } from "./ResponseValidation";

const PACKAGE_DELETED = "Package deleted successfully";

export async function desactivatePackageById(token, id)
{
  const url = env2.api + endPoint.desactivatePackageById + id;
  const response = await getRequest(token, url);
  return getResultAndShowSuccess(response, PACKAGE_DELETED);
}

export async function getAllPackagesAsync(token) {
  const url = env2.api + endPoint.packages;
  const response = await getRequest(token, url);
  return getMsgResult(response);
}

export async function getPackageByIdAsync(token, packageId) {
  const url = env2.api + endPoint.package + packageId;
  const response = await getRequest(token, url);
  return getMsgResult(response);
}

export async function postPackageAsync(token, packageData) {
  const url = env2.api + endPoint.addPackage;
  let formdata = new FormData();
      formdata.append("name", packageData.name);
      formdata.append("description", packageData.description);
      formdata.append("type", packageData.type);
  packageData.fk_artifacts.forEach((element)=>{formdata.append("fk_artifacts",element)});

  let response;
  if (packageData?.anexo) {
    formdata.append("anexo", packageData.anexo);    
  }

  response = await postRequest(token, url, formdata);
  const resultOk = getResult(response);
  return resultOk;
}

export async function getAnexoPackage(token, packageId, packageName) {
  const url = env2.api + endPoint.downloadAnexoPackage + packageId;
  await downloadFile(token, url, packageName);
}

export async function getPackagePageMinAsync(token, filterData) {
  const url = env2.api + endPoint.packagePageMin + filterData.pageSize + "/" + filterData.pageNumber + "?col=" + filterData.filter + "&order=" + filterData.order;
  let body = new FormData();
  
  if (filterData.filterName !== undefined && filterData.filterName !== "") {
    body = JSON.stringify({"name": filterData.filterName});
  }

  const response = await postRequest(token, url, body)
  const result = getMsgResult(response);
  return result;
}

export async function updatePackageAsync(token, id, packageData) {
  const url = env2.api + endPoint.changePackage + id;
  let formdata = new FormData();
      formdata.append("name", packageData.name);
      formdata.append("description", packageData.description);
      formdata.append("type", packageData.type);
  packageData.fk_artifacts.forEach((element)=>{formdata.append("fk_artifacts",element)});

  if(packageData?.anexo !== null &&  packageData?.anexo !== ""){
    formdata.append("anexo", packageData.anexo);
  }else{
    formdata.append("anexo", "CLSFILE");
  }

  const response = await updateRequest(token, url, formdata);
  const result = getResult(response);
  return result;
}

export async function getPackagePageAdvancedAsync(token, size, Nopage, orderby, order, bodyContent) {
  const url = env2.api + endPoint.packagePageAdvanced + size + "/" + Nopage + "?col=" + orderby + "&order=" + order;

  let ArrayBody = {};
  if (bodyContent.name) {
    ArrayBody.name= bodyContent.name;
  }
  if (bodyContent.Type) {
      ArrayBody.type= bodyContent.Type;
  }
  if (bodyContent.Status) {
    ArrayBody.tagTest= bodyContent.Status;
  }
  const body = JSON.stringify(ArrayBody);
  const response = await postAppJsonRequest(token, url, body);
  const result = getMsgResult(response);
  return result;
}

export async function GetPackageSize(token){
  const url = env2.api + endPoint.packageSize;
  const response = await getRequest(token, url);
  return getMsgResult(response);
};