import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";

import {
  ThemeProvider,
  Checkbox,
  Typography,
  TablePagination,
} from "@mui/material";

import {
  pageTheme,
  StyledDataGrid,
  QuickSearchToolbar,
  escapeRegExp,
  CustomLoadingOverlay,
} from "../../../../../../Helpers/Themes";
import { getClientsAsync } from "../../../../../../Services/Clients";
import { GridOverlay } from "@mui/x-data-grid-pro";

export default function AgentsToAll(props) {
  const { DataUser, ValuesCheckedAgentsAll } = props;
  const [tempRowsForAllAgentsClients, settempRowsForAllAgentsClients] =
    useState([]);
  const [checkedAgentsAll, setCheckedAgentsAll] = useState(
    ValuesCheckedAgentsAll
  );
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectionModel, setSelectionModel] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [totalRows, settotalRows] = useState([]);
  const [countAllAgents, setCountAllAgents] = useState(0);
  const [page, setPage] = useState(0);

  const newGetAllAgentsClients = useCallback(async () => {
    setLoading(true);

    const response = await getClientsAsync(DataUser.token);
    if (response) {
      let array = [];
      response.forEach((element) => {
        array.push({
          id: element._id,
          online: element.online,
          hostname: element?.hostname
            ? element.hostname
            : element.workUser[0].hostname,
          ip:
            element.IPInformation.length > 0
              ? element.IPInformation[0].public.IP
              : "N/A",
          private:
            element.IPInformation.length > 0
              ? element.IPInformation[0].private.IP
              : "N/A",
          port:
            element.IPInformation.length > 0
              ? element.IPInformation[0].port
              : "N/A",
          osInformation: element.osInformation[0].name,
        });
      });
      setCountAllAgents(array.length);
      settempRowsForAllAgentsClients(array);
      settotalRows(array);
      setLoading(false);
    } else {
      settempRowsForAllAgentsClients([]);
      settotalRows([]);
    }
  }, [DataUser.token]);

  useEffect(() => {
    newGetAllAgentsClients();
  }, [newGetAllAgentsClients]);

  useEffect(() => {
    setSelectionModel(ValuesCheckedAgentsAll);
  }, [ValuesCheckedAgentsAll]);

  const handleToggleAgentsOnlineAll = (value) => () => {
    const currentIndex = checkedAgentsAll.indexOf(value);
    const newChecked = [...checkedAgentsAll];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedAgentsAll(newChecked);
    props.handleCheckedAgentsAll(newChecked);
  };

  const Columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.05,
      filterable: false,
      sortable: false,
      minWidth: 40,
      align: "center",
      renderHeader: () => <Box style={{ color: "#0a0818" }}>|</Box>,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <Checkbox
          color="success"
          onClick={handleToggleAgentsOnlineAll(params.id)}
          checked={selectionModel.indexOf(params.id) !== -1}
        ></Checkbox>
      ),
    },
    {
      field: "hostname",
      headerName: "Host Name",
      flex: 1,
    },
    {
      field: "private",
      headerName: "Ip Private",
      sortable: false,
      filterable: false,
      flex: 0.5,
    },
    {
      field: "osInformation",
      headerName: "Operating System",
      sortable: false,
      filterable: false,
      flex: 1,
    },
  ];
  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = totalRows.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
    settempRowsForAllAgentsClients(filteredRows);
    let NoTotal = filteredRows.length;
    setCountAllAgents(NoTotal);
    setPage(0);
    if (searchValue === "") {
      settempRowsForAllAgentsClients(totalRows);
      let NoTotal = filteredRows.length;
      setCountAllAgents(NoTotal);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(
      page ===
        Math.ceil(tempRowsForAllAgentsClients.length / event.target.value)
        ? 0
        : page
    );
    setRowsPerPage(event.target.value);
  };
  return (
    <>
      <ThemeProvider theme={pageTheme}>
        <Box
          style={{
            width: "100%",
            height: "510px",
            backgroundColor: "rgb(36, 41, 51)",
            border: "1px solid #1e1c3a",
            borderRadius: "8px",
          }}
        >
          <Typography sx={{ marginTop: "10px", textAlign: "center" }}>
            Select Endpoints
          </Typography>
          <div style={{ maxHeight: "480px", overflow: "auto", width: "100%" }}>
            <StyledDataGrid
              components={{
                Toolbar: QuickSearchToolbar,
                LoadingOverlay: CustomLoadingOverlay,
                NoRowsOverlay: () => (
                  <GridOverlay>
                    <div>No Data Results</div>
                  </GridOverlay>
                ),
                NoResultsOverlay: () => (
                  <GridOverlay>
                    <div>No Results Filter Data</div>
                  </GridOverlay>
                ),
                Pagination: () => (
                  <>
                    <TablePagination
                      component="div"
                      count={countAllAgents}
                      rowsPerPageOptions={[5, 10, 20]}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      showFirstButton
                      showLastButton
                    />
                  </>
                ),
              }}
              rows={tempRowsForAllAgentsClients}
              columns={Columns}
              pagination
              pageSize={rowsPerPage}
              rowsPerPageOptions={[5, 10, 20]}
              rowCount={countAllAgents}
              page={page}
              onPageChange={handleChangePage}
              onPageSizeChange={(PerPage) => setRowsPerPage(PerPage)}
              // checkboxSelection
              // onSelectionModelChange={(newSelectionModel) => {
              //   setSelectionModel(newSelectionModel);
              //   props.handleCheckedAgentsAll(newSelectionModel);
              // }}
              // selectionModel={selectionModel}
              disableSelectionOnClick
              loading={loading}
              density="comfortable"
              autoHeight
              componentsProps={{
                toolbar: {
                  value: searchText,
                  onChange: (event) => requestSearch(event.target.value),
                  clearSearch: () => requestSearch(""),
                },
              }}
            />
          </div>
        </Box>
      </ThemeProvider>
    </>
  );
}
