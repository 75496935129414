import React, { useState, useEffect, useCallback } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Button, Checkbox, LinearProgress } from "@mui/material";
import { myColor } from "../../../../../../BaseCss";
import refreshIcon from "../../../../../../assets/images/reset-icon.svg";
import { getOnlineClientsAsync } from "../../../../../../Services/Clients";

const ButtonRows = styled(Button)(({ theme }) => ({
  margin: "5px",
  padding: "10px",
  float: "right",
  color: theme.palette.getContrastText("#c59d48"),
  backgroundColor: "#c59d48",
  border: "1px solid #1e1c3a",
  "&:hover": {
    backgroundColor: "#c59d48",
  },
}));

export default function AgentsOnline(props) {
  const { DataUser, ValuesCheckedAgentsOnline, AgentConnect } = props;
  const [tempRowsForAgentsClientsOnline, settempRowsForAgentsClientsOnline] =
    useState([]);
  const [louder, setLouder] = useState(false);
  const [checkedAgents, setCheckedAgents] = useState(ValuesCheckedAgentsOnline);
  const newGetAgentsOnline = useCallback(async () => {
    const onlineClientsResponse = await getOnlineClientsAsync(DataUser.token);
    if (onlineClientsResponse) {
      let array = [];
      onlineClientsResponse.forEach((element) => {
        array.push(element);
      });
      settempRowsForAgentsClientsOnline(array);
      statusChange();
    } else {
      settempRowsForAgentsClientsOnline([]);
      statusChange();
    }
  }, [DataUser.token]);
  useEffect(() => {
    setCheckedAgents(ValuesCheckedAgentsOnline);
  }, [ValuesCheckedAgentsOnline]);

  useEffect(() => {
    newGetAgentsOnline();
  }, [AgentConnect, newGetAgentsOnline]);

  const statusChange = () => {
    setLouder(true);
  };

  const handleToggleAgentsOnline = (value) => () => {
    const currentIndex = checkedAgents.indexOf(value);
    const newChecked = [...checkedAgents];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedAgents(newChecked);
    props.handleCheckedAgentsOnline(newChecked);
  };

  return (
    <div style={{ height: "auto" }}>
      <Box
        style={{
          width: "100%",
          height: "510px",
          maxHeight: "510px",
          backgroundColor: "rgb(36, 41, 51)",
          border: "1px solid #1e1c3a",
          borderRadius: "8px",
          overflow: "auto",
        }}
      >
        <Typography sx={{ marginTop: "10px", textAlign: "center" }}>
          Select Endpoints
        </Typography>
        <Tooltip title="Refresh" arrow>
          <ButtonRows>
            <img src={refreshIcon} alt=""></img>
          </ButtonRows>
        </Tooltip>
        {!louder ? (
          <LinearProgress color="secondary" />
        ) : (
          <Table style={{ overflow: "auto" }}>
            <TableHead
              style={{
                backgroundColor: "#0d0b21",
                borderRadius: "12px",
              }}
            >
              <TableRow>
                <TableCell
                  style={{
                    borderBottom: "1px solid #1e1c3a",
                  }}
                >
                  <Typography style={{ color: myColor.VeryLight }}></Typography>
                </TableCell>
                <TableCell
                  style={{
                    borderBottom: "1px solid #1e1c3a",
                  }}
                >
                  <Typography style={{ color: myColor.VeryLight }}>
                    Host Name
                  </Typography>
                </TableCell>

                <TableCell
                  style={{
                    borderBottom: "1px solid #1e1c3a",
                  }}
                >
                  <Typography style={{ color: myColor.VeryLight }}>
                    Ip private
                  </Typography>
                </TableCell>
                <TableCell
                  style={{
                    borderBottom: "1px solid #1e1c3a",
                  }}
                >
                  <Typography style={{ color: myColor.VeryLight }}>
                    Platform
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tempRowsForAgentsClientsOnline.length > 0
                ? tempRowsForAgentsClientsOnline.map((row, index) => (
                    <TableRow key={row.id}>
                      <TableCell
                        style={{
                          color: "#66638f",
                          borderBottom: "1px solid #1e1c3a",
                        }}
                      >
                        <Checkbox
                          onClick={handleToggleAgentsOnline(row._id)}
                          checked={checkedAgents.indexOf(row._id) !== -1}
                                                 
                        ></Checkbox>
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#66638f",
                          borderBottom: "1px solid #1e1c3a",
                        }}
                      >
                        <p
                          style={{
                            minWidth: "240px",
                            maxWidth: "295px",
                            overflow: "hidden",
                            whiteSpace: "initial",
                            textOverflow: "ellipsis",
                            textAlign: "justify",
                          }}
                        >
                          {row?.hostname
                            ? row.hostname
                            : row.workUser[0].hostname}
                        </p>
                      </TableCell>

                      <TableCell
                        style={{
                          color: "#66638f",
                          borderBottom: "1px solid #1e1c3a",
                        }}
                      >
                        {row.IPInformation[0].private.IP}
                      </TableCell>

                      <TableCell
                        style={{
                          color: "#66638f",
                          borderBottom: "1px solid #1e1c3a",
                        }}
                      >
                        {row.osInformation[0].platform}
                      </TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        )}
        {tempRowsForAgentsClientsOnline.length === 0 ? (
          <Box style={{ margin: "10px" }}>
            <label>No Endpoints Connected</label>
          </Box>
        ) : null}
      </Box>
    </div>
  );
}
