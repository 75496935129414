import { env2 } from "../Helpers/Instance";
import { NotifyInfo } from "../Helpers/Scripts";
import { endPoint } from "./EndPoint";
import {
  postAppJsonRequest, getRequest, postRequest, updateRequest,
  downloadFile, updateFile
} from "./FetchRequests";
import { getMsgResult, getResultAndShowSuccess } from "./ResponseValidation";

const ARTIFACT_LOADED = "Artifact loaded successfully";
const PDF_UPDATED = "Pdf file updated successfully";
const ARTIFACT_DELETED = "Artifact deleted successfully";
const PREPARING_ARTIFACT = "Validating session to prepare artifact, please wait";

export async function GetArtifactByIdAsync(token, id) {
  const url = env2.api + endPoint.artifact + id;
  const response = await getRequest(token, url);
  return getMsgResult(response);
}

export async function getTotalArtifactsAsync(token) {
  const url = env2.api + endPoint.totalArtifacts;
  let response = await getRequest(token, url);
  return getMsgResult(response);
}

export async function getArtifactsPageAsync(token, size, Nopage, orderby, order, bodyContent) {
  const url = env2.api + endPoint.artifactsPageAdvanced + size + "/" + Nopage + "?col=" + orderby + "&order=" + order;
  var ArrayBody = {};
  if (bodyContent.name) {
    ArrayBody.name = bodyContent.name;
  }
  if (bodyContent.threatType) {
    ArrayBody.threatType = bodyContent.threatType;
  }
  if (bodyContent.phaseAttacks) {
    ArrayBody.phaseAttacks = bodyContent.phaseAttacks;
  }
  if (bodyContent.tagTest) {
    ArrayBody.tagTest = bodyContent.tagTest;
  }
  if (bodyContent.platform) {
    ArrayBody.platform = bodyContent.platform;
  }
  const body = JSON.stringify(ArrayBody)
  const response = await postAppJsonRequest(token, url, body);
  return getMsgResult(response);
}

export async function getArtifactMinPageAsync(token, filterData) {
  const url = env2.api + endPoint.artifactsPageMin + filterData.pageSize + "/" + filterData.pageNumber + "?col=" + filterData.filter + "&order=" + filterData.order;
  var body = "";
  let response;
  if (filterData.filterName !== undefined && filterData.filterName !== "") {
    body = JSON.stringify({ "name": filterData.filterName });
    response = await postAppJsonRequest(token, url, body);
  } else {
    response = await postRequest(token, url, body);
  }

  const result = await getMsgResult(response);
  return result;
}

export async function addArtifactAsync(token, artifact) {
  let url = env2.api + endPoint.addArtifact;
  var formData = new FormData();
  formData.append("platform", artifact.platform);
  formData.append("file", artifact.file);
  formData.append("fk_threatType", artifact.fk_threatType);
  artifact.phaseAttacks.forEach((phaseAttac) => formData.append("phaseAttacks", phaseAttac));
  formData.append("description", artifact.description);

  formData.append("callback", artifact.isCallback);
  formData.append("evasion", artifact.isEvasion);

  if (artifact.manifest) {
    formData.append("manifest", artifact.manifest);
  }
  if (artifact.resolutionNetwork) {
    formData.append("resolutionNetwork", artifact.resolutionNetwork);
  }
  if (artifact.resolutionEndPoint) {
    formData.append("resolutionEndPoint", artifact.resolutionEndPoint);
  }
  if (artifact.resolutionCallback) {
    formData.append("resolutionCallback", artifact.resolutionCallback);
  }
  if (artifact.urlMitre) {
    formData.append("urlMitre", artifact.urlMitre);
  }
  if (artifact.anexo) {
    formData.append("anexo", artifact.anexo);
  }
  const response = await updateRequest(token, url, formData);
  return getResultAndShowSuccess(response, ARTIFACT_LOADED);
}

export async function downloadArtifactAsync(token, artifactData) {
  const url = env2.api + endPoint.downloadArtifact + artifactData.id + "/ZIP";
  const name = artifactData.name + '.zip'
  NotifyInfo(PREPARING_ARTIFACT);
  await downloadFile(token, url, name);
}

export async function downloadArtifactPdfAsync(token, pdfData) {
  const url = env2.api + endPoint.downloadArtifactPdf + pdfData.id;
  await downloadFile(token, url, pdfData.name);
}

export async function updatePdfAsync(token, fileData) {
  fileData.url = env2.api + endPoint.updatePdf + fileData.id;
  const response = await updateFile(token, fileData);
  return getResultAndShowSuccess(response, PDF_UPDATED)
}

export async function deleteArtifactByIdAsync(token, id) {
  const url = env2.api + endPoint.deleteArtifact + id;
  const response = await getRequest(token, url);
  return getResultAndShowSuccess(response, ARTIFACT_DELETED);
}