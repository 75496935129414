import { GetDate } from "../../../Helpers/Scripts";
import { MitreDataInitial } from "./ConstansReport";
import { getReportData } from "../../../Services/Reports";

export function ConvertDate(day) {
  let converday = new Date(day);
  return converday.toString();
}
function checkErrorData(response) {
  if (response === undefined || response.Reports.historys.length === 0) {
    return 1;
  } else {
    let flag = false;
    response.Reports.historys.forEach((report) => {
      if (report.general[0].event !== "notSent") {
        flag = true;
      }
    });

    if (!flag) {
      return 2;
    } else {
      let flag2 = false;
      response.Reports.historys.forEach((report) => {
        if (report.packages.length > 0) {
          flag2 = true;
        }
      });
      if (!flag2) {
        return 3;
      } else {
        let flag3 = false;
        response.Reports.historys.forEach((report) => {
          report.packages.forEach((pack) => {
            if (pack.artifacts.length > 0) {
              flag3 = true;
            }
          });
        });
        if (!flag3) {
          return 4;
        } else {
          return 0;
        }
      }
    }
  }
}
function GetClientsList(array) {
  let count = [];
  if (array !== undefined) {
    array.Reports.historys.forEach((client) => {
      if (count.length === 0) {
        count.push({ hostname: client.hostname });
      } else {
        let flag = false;
        for (let j = 0; j < count.length; j++) {
          if (count[j].hostname === client.hostname) {
            flag = true;
          }
        }
        if (!flag) {
          count.push({ hostname: client.hostname });
        }
      }
    });
  }
  return count.length;
}

function limitVector(vector) {
  let valor = 4;
  switch (vector) {
    case "RealExecution":
      valor = 8;
      break;
    case "EPP":
      valor = 6;
      break;
    case "NetworkSecurity":
      valor = 4;
      break;
    default:
      break;
  }
  return valor;
}

function GenerateMitreData(list, MitrePhase) {
  let Descript = "";

  let Name = "";
  for (let index = 0; index < MitrePhase.length; index++) {
    if(list?.length){
      for (let j = 0; j < list.length; j++) {
        if (MitrePhase[index] === list[j].name) {
          if (Descript === "") {
            Name = list[j]["name"];
            Descript = list[j]["description"];
          } else {
            Name += " & " + list[j]["name"];
            Descript += "," + list[j]["description"];
          }
        }
      }
    }
    
  }
  let array = { Text: Name, Description: Descript };
  return array;
}
function CheackError(Hystory) {
  let ErrorBool = false;
  let Description = "";
  for (let index = 0; index < Hystory.length; index++) {
    let value = Hystory[index].value;
    if (value < 1) {
      ErrorBool = true;
      if (Description === "") {
        Description =
          Hystory[index].event.toUpperCase() +
          " : " +
          Hystory[index].msg +
          "\r";
      } else {
        Description +=
          Hystory[index].event.toUpperCase() + ":" + Hystory[index].msg + "\r";
      }
    }
  }
  let array = { Error: ErrorBool, Description: Description };
  return array;
}

function GetTableArtifacts(array, list, Vector) {
  let matrixArtefact = [];
  let matrixExecution = [];
  let PackagesData = [];
  let cont = 1;
  
  array.Reports.historys.forEach((element, index) => {
    if (element.packages.length > 0) {
      let nameClient = element.hostname;
      let StartDate = GetDate(element.general[0]?.createdAt),
        FinishDate = GetDate(element.general[0]?.updatedAt);
      element.packages.forEach((Pack) => {
        if (index === 0) {
          PackagesData.push({
            Name: Pack.name,
            Description: Pack.description,
          });
        }
        for (let j = 0; j < Pack.artifacts.length; j++) {
          if (StartDate === "") {
            StartDate = GetDate(Pack.artifacts[j].history[0]?.createdAt)
              ? GetDate(Pack.artifacts[j].history[0].createdAt)
              : "";
          } else {
            let minDate = new Date(StartDate);
            let newDate = GetDate(Pack.artifacts[j].history[0]?.createdAt)
              ? new Date(Pack.artifacts[j].history[0].createdAt)
              : minDate;
            if (minDate > newDate) {
              StartDate = GetDate(Pack.artifacts[j].history[0].createdAt);
            }
          }
          if (FinishDate === "") {
            FinishDate = Pack.artifacts[j].history[
              Pack.artifacts[j].history.length - 1
            ]?.updatedAt
              ? Pack.artifacts[j].history[Pack.artifacts[j].history.length - 1]
                  .updatedAt
              : "";
          } else {
            let minDate = new Date(FinishDate);
            let newDate = Pack.artifacts[j].history[
              Pack.artifacts[j].history.length - 1
            ]?.updatedAt
              ? new Date(
                  Pack.artifacts[j].history[
                    Pack.artifacts[j].history.length - 1
                  ]?.updatedAt
                )
              : minDate;
            if (minDate > newDate) {
              FinishDate =
                Pack.artifacts[j].history[Pack.artifacts[j].history.length - 1]
                  .updatedAt;
            }
          }

          matrixArtefact.push({
            id: cont++,
            _id: Pack.artifacts[j].IDArtifact,
            client: nameClient,
            name: Pack.artifacts[j].name,
            callback: Pack.artifacts[j].callback,
            C2: Pack.artifacts[j].C2,
            PhasesMitre: GenerateMitreData(
              list,
              Pack.artifacts[j].phaseAttacks
            ),
            Url: Pack.artifacts[j]?.url ? Pack.artifacts[j].url : "",
            start: Pack.artifacts[j].history[0]?.createdAt
              ? GetDate(Pack.artifacts[j].history[0].createdAt)
              : "Not start",
            finish: Pack.artifacts[j].history[
              Pack.artifacts[j].history.length - 1
            ]?.updatedAt
              ? GetDate(
                  Pack.artifacts[j].history[
                    Pack.artifacts[j].history.length - 1
                  ].updatedAt
                )
              : "Not finish",
            description: Pack.artifacts[j].description,
            packageName: Pack.name,
            vector: array.vector,
            satisfactory: Pack.artifacts[j].satisfactory,
            ErrorDetails: CheackError(Pack.artifacts[j].history),
            fase1:
              Pack.artifacts[j].history[0]?.value && Vector > 3
                ? Pack.artifacts[j].history[0].value
                : 0,
            msgFase1:
              Pack.artifacts[j].history[0]?.msg && Vector > 3
                ? Pack.artifacts[j].history[0].msg
                : 0,
            fase2:
              Pack.artifacts[j].history[1]?.value && Vector > 3
                ? Pack.artifacts[j].history[1].value
                : 0,
            msgFase2:
              Pack.artifacts[j].history[1]?.msg && Vector > 3
                ? Pack.artifacts[j].history[1].msg
                : 0,
            fase3:
              Pack.artifacts[j].history[2]?.value && Vector > 5
                ? Pack.artifacts[j].history[2].value
                : 0,
            msgFase3:
              Pack.artifacts[j].history[2]?.msg && Vector > 5
                ? Pack.artifacts[j].history[2].msg
                : 0,
            fase4:
              Pack.artifacts[j].history[3]?.value && Vector > 5
                ? Pack.artifacts[j].history[3].value
                : 0,
            msgFase4:
              Pack.artifacts[j].history[3]?.msg && Vector > 5
                ? Pack.artifacts[j].history[3].msg
                : 0,
            fase5:
              Pack.artifacts[j].history[4]?.value && Vector > 7
                ? Pack.artifacts[j].history[4].value
                : 0,
            msgFase5:
              Pack.artifacts[j].history[4]?.msg && Vector > 7
                ? Pack.artifacts[j].history[4].msg
                : 0,
            fase6:
              Pack.artifacts[j].history[5]?.value && Vector > 7
                ? Pack.artifacts[j].history[5].value
                : 0,
            msgFase6:
              Pack.artifacts[j].history[5]?.msg && Vector > 7
                ? Pack.artifacts[j].history[5].msg
                : 0,
          });
        }
      });
      matrixExecution.push({
        client: nameClient,
        Success: element.statistics.successful,
        Faild: element.statistics.failed,
        Qualification: element.statistics.qualification,
        StartDate: StartDate?.toString(),
        FinishDate: FinishDate?.toString(),
        Message: element.general[0].msg,
      });
    } else {
      if (element?.hostname) {
        let nameClient = element.hostname;
        let StartDate = GetDate(element.general[0].createdAt),
          FinishDate = GetDate(element.general[0].updatedAt);
        matrixExecution.push({
          client: nameClient,
          Success: element.statistics.successful,
          Faild: element.statistics.failed,
          Qualification: element.statistics.qualification,
          StartDate: StartDate?.toString(),
          FinishDate: FinishDate?.toString(),
          Message: element.general[0].msg,
        });
      }
    }
  });
   
  for (let index = 0; index < matrixArtefact.length; index++) {
    if (!matrixArtefact[index].fase1 && Vector > 3) {
      matrixArtefact[index].fase1 = false;
    }
    if (!matrixArtefact[index].fase2 && Vector > 4) {
      matrixArtefact[index].fase2 = false;
    }
    if (!matrixArtefact[index].fase3 && Vector > 5) {
      matrixArtefact[index].fase3 = false;
    }
    if (!matrixArtefact[index].fase4 && Vector > 6) {
      matrixArtefact[index].fase4 = false;
    }
    if (!matrixArtefact[index].fase5 && Vector > 7) {
      matrixArtefact[index].fase5 = false;
    }
    if (!matrixArtefact[index].fase6 && Vector === 8) {
      matrixArtefact[index].fase6 = false;
    }
  }

  return {
    PackagesGlobal: PackagesData,
    ExecutionTable: matrixExecution,
    tabledata1: matrixArtefact,
  };
}

function ExecutionsCountSuccessAndFail(Executions) {
  let arraySuccess = [];
  let arrayFailed = [];
  Executions.forEach((element) => {
    arraySuccess.push(element.Success);
    arrayFailed.push(element.Faild);
  });
  let totalArtifactsSuccess = arraySuccess.reduce((a, b) => a + b, 0);
  let totalArtifactsFailed = arrayFailed.reduce((a, b) => a + b, 0);
  let total = totalArtifactsSuccess + totalArtifactsFailed;

  let successresult =
    arraySuccess !== 0 ? Math.round((totalArtifactsSuccess * 100) / total) : 0;
  let faildedresult =
    arrayFailed !== 0 ? Math.round((totalArtifactsFailed * 100) / total) : 0;

  return {
    SuccesResultExecution: successresult,
    FailedResultExecition: faildedresult,
    SuccesResultArtifacts: totalArtifactsSuccess,
    FailedResultArtifacts: totalArtifactsFailed,
    CountSendArtifacts: total,
  };
}
const handleThreatlist = (MitrePhase, list) => {
  let Descript = "";
  let Name = "";
  for (let index = 0; index < MitrePhase.length; index++) {
    for (let j = 0; j < list.length; j++) {
      if (MitrePhase[index].type === list[j].name) {
        if (Descript === "") {
          Name = list[j]["name"];
          Descript = list[j]["description"];
        } else {
          Name += " & " + list[j]["name"];
          Descript += "," + list[j]["description"];
        }
      }
    }
  }
  let array = { Name: Name, Descript: Descript };
  return array;
};
function GetMitre(array, list) {
  let PhasesMitre = [];
  let MitreData = MitreDataInitial;

  array.Reports.historys.forEach((client) => {
    client.packages.forEach((Pack) => {
      Pack.artifacts.forEach((artifact) => {
        artifact.phaseAttacks.forEach((phase) => {
          if (PhasesMitre.length === 0) {
            PhasesMitre.push({
              type: phase,
              satisfactory: artifact.satisfactory,
            });
          } else {
            let flag = false;
            for (let j = 0; j < PhasesMitre.length; j++) {
              if (PhasesMitre[j].type === phase) {
                if (!artifact.satisfactory) {
                  PhasesMitre[j].satisfactory = artifact.satisfactory;
                }
                flag = true;
              }
            }
            if (!flag) {
              PhasesMitre.push({
                type: phase,
                satisfactory: artifact.satisfactory,
              });
            }
          }
        });
      });
    });
  });
  let DataMitre = handleThreatlist(PhasesMitre, list);
  let long = 0;
  PhasesMitre.forEach((element) => {
    switch (element.type) {
      case "Initial Access": {
        MitreData.InitialRecon = element.satisfactory ? 1 : 2;
        long = long > 2 ? long : 2;
        break;
      }

      case "Execution": {
        MitreData.InitialCompromise = element.satisfactory ? 1 : 2;
        long = long > 3 ? long : 3;
        break;
      }
      case "Defense Evasion": {
        MitreData.InitialCompromise = element.satisfactory ? 1 : 2;
        long = long > 3 ? long : 3;
        break;
      }

      case "Persistence": {
        MitreData.EstabishFoothold = element.satisfactory ? 1 : 2;
        MitreData.EscaletePrivileges = element.satisfactory ? 1 : 2;
        MitreData.InternalRecon = element.satisfactory ? 1 : 2;
        long = long > 5 ? long : 5;
        break;
      }
      case "Privilege Escalation": {
        MitreData.EstabishFoothold = element.satisfactory ? 1 : 2;
        MitreData.EscaletePrivileges = element.satisfactory ? 1 : 2;
        long = long > 4 ? long : 4;
        break;
      }

      case "Credential Access": {
        MitreData.EscaletePrivileges = element.satisfactory ? 1 : 2;
        long = long > 4 ? long : 4;
        break;
      }
      case "Command and Control": {
        MitreData.EscaletePrivileges = element.satisfactory ? 1 : 2;
        long = long > 4 ? long : 4;
        break;
      }

      case "Discovery": {
        MitreData.InternalRecon = element.satisfactory ? 1 : 2;
        long = long > 5 ? long : 5;
        break;
      }
      case "Lateral Movement": {
        MitreData.InternalRecon = element.satisfactory ? 1 : 2;
        long = long > 5 ? long : 5;
        break;
      }
      case "Collection": {
        MitreData.InternalRecon = element.satisfactory ? 1 : 2;
        long = long > 5 ? long : 5;
        break;
      }

      case "Exfiltration": {
        MitreData.CompleteMission = element.satisfactory ? 1 : 2;
        long = 6;
        break;
      }

      default:
        break;
    }
  });
  return {
    DataMitre: DataMitre,
    MtreLong: long,
    MitreArray: MitreData,
  };
}

export const GenerateDataReport = async (
  DataUser,
  ReportDataProps,
  Body = ""
) => {
  const response = await getReportData(
    DataUser.token,
    ReportDataProps.id,
    Body
  );
  
  let returnValue = {
    FoundMissData: checkErrorData(response),
    CheckData: true,
    ReportData: response,
    clientsCount: GetClientsList(response),
    vector: limitVector(response?.vector ? response.vector : "NetworkSecurity"),
  };
  if (returnValue.FoundMissData !== 1) {
    let dataProsessing = GetTableArtifacts(
      response,
      DataUser.List,
      returnValue.vector
    );
    returnValue.PackagesGlobal = dataProsessing.PackagesGlobal;
    returnValue.ExecutionTable = dataProsessing.ExecutionTable;
    returnValue.tabledata1 = dataProsessing.tabledata1;
    let graphData = ExecutionsCountSuccessAndFail(returnValue.ExecutionTable);
    returnValue.SuccesResultExecution = graphData.SuccesResultExecution;
    returnValue.FailedResultExecution = graphData.FailedResultExecition;
    returnValue.SuccesResultArtifacts = graphData.SuccesResultArtifacts;
    returnValue.FailedResultArtifacts = graphData.FailedResultArtifacts;
    returnValue.CountSendArtifacts = graphData.CountSendArtifacts;
    let mitreDatas = GetMitre(returnValue.ReportData, DataUser.List);
    returnValue.attackName = mitreDatas.DataMitre.Name;
    returnValue.attackDescription = mitreDatas.DataMitre.Descript;
    returnValue.MtreLong = mitreDatas.MtreLong;
    returnValue.MitreArray = mitreDatas.MitreArray;
  }
  return returnValue;
};

export function ArrayDates(array) {
  array.sort(function (a, b) {
    return new Date(b.date) - new Date(a.date);
  });
  let internalindex = 0;
  let arrayFinal = [];
  for (let index = 0; index < array.length; index++) {
    const Fecha = array[index];
    for (let index2 = Fecha.hours.length; index2 > 0; index2--) {
      arrayFinal.push({
        index: internalindex.toString(),
        label: Fecha.date + " " + Fecha.hours[index2 - 1],
        date: Fecha.date,
        hour: Fecha.hours[index2 - 1],
      });
      internalindex++;
    }
  }
  return arrayFinal;
}

export function ExportExcel(typeStatus, matrixArtefact, Vector) {
  let dataExcel = [];
  for (let i = 0; i < matrixArtefact.length; i++) {
    var nextSource = matrixArtefact[i];
    let NetworkSecurity = "";
    let EPP = "";
    let ExecutedFile = "";
    let Satisfactory = "";
    let myTrue = typeStatus ? "Success" : "Fail";
    let myFalse = typeStatus ? "Fail" : "Success";

    nextSource.fase1 && nextSource.fase2
      ? (NetworkSecurity = myTrue)
      : (NetworkSecurity = myFalse);
    if(Vector === 4){
      EPP = "N/A";      
      ExecutedFile = "N/A";
    }else if (Vector === 6){
      if (nextSource.fase3 === null && nextSource.fase4 === null) {
        EPP = "N/A";
      } else {
        nextSource.fase3 && nextSource.fase4 ? (EPP = myTrue) : (EPP = myFalse);
      }
      ExecutedFile = "N/A";
    }else {
      if (nextSource.fase3 === null && nextSource.fase4 === null) {
        EPP = "N/A";
      } else {
        nextSource.fase3 && nextSource.fase4 ? (EPP = myTrue) : (EPP = myFalse);
      }
      if (nextSource.fase3 === null && nextSource.fase4 === null) {
        ExecutedFile = "N/A";
      } else {
        nextSource.fase5 && nextSource.fase6
          ? (ExecutedFile = myTrue)
          : (ExecutedFile = myFalse);
      }
    }
    nextSource.satisfactory
      ? (Satisfactory = myTrue)
      : (Satisfactory = myFalse);
    let valueC2 =
    nextSource.C2 === "Failed"
              ? nextSource.C2 === "Failed" || nextSource.C2 === "Needless"
                ? myFalse 
                : myTrue
              : nextSource.C2 === "Needless"
              ? "N/A"
              : myTrue;
    dataExcel.push({
      client: nextSource.client,
      file: nextSource.name,
      start: nextSource.start === 'Not start' ?  'Not start ': nextSource.start,
      finish: nextSource.finish === 'Not finish' ? 'Not finish' : nextSource.finish,
      networkSecurity: NetworkSecurity,
      epp: EPP,
      executedFile: ExecutedFile,
      C2: valueC2,
      satisfactory: Satisfactory,
      packageName: nextSource.packageName,
    });
  }
  let expo = {
    data: dataExcel,
    colums: [
      { label: "Client", value: "client" },
      { label: "File", value: "file" },
      { label: "StartGMT", value: "start" },
      { label: "FinishGMT", value: "finish" },
      { label: "Network", value: "networkSecurity" },
      { label: "Endpoint", value: "epp" },
      { label: "Execution", value: "executedFile" },
      { label: "C2", value: "C2" },
      { label: "Emulation Status", value: "satisfactory" },
      { label: "Package", value: "packageName" },
    ],
  };
  return expo;
}
