import React from 'react';
import "../../Packages.scss";
import PdfForm from "./PdfForm";
import NameForm from "./NameForm";
import TypeForm from "./TypeForm";
import ArtifactsForm from "./ArtifactsForm";
import DescriptionForm from "./DescriptionForm";
import { PageButton } from "../../PackageTheme";
import { Box, Typography, Paper, Grid, Modal } from "@mui/material";
import { postPackageAsync, updatePackageAsync } from '../../../../Services/Package';
import { Coronavirus, Description, DriveFileRenameOutline, MergeType, PictureAsPdf } from '@mui/icons-material';
import { NotifyError, NotifySuccess } from '../../../../Helpers/Scripts';

const AddModeLabels = {
    title: "New Package",
    forms: {
        name: "Enter a name",
        type: "Choose a type",
        description: "Enter a description",
        pdfFile: "Choose a pdf file",
        artifacts: "Choose Artifacts",
        finish: {
            succesfuly: "Successfully.",
            unsuccesfuly: "Fails to create package."
        }
    }
}

const EditModelLabels = {
    title: "Edit Package",
    forms: {
        name: "Edit name",
        type: "Edit type",
        description: "Edit description",
        pdfFile: "Change PDF file",
        artifacts: "Edit Artifacts",
        finish: {
            succesfuly: "The package was edited successfully.",
            unsuccesfuly: "Fails to edit package."
        }
    }
}
function StepColor(value, compare) {
    let color = "#090f1a";
    if (compare === value) {
        color = "#c59d48";
    }
    if (compare > value) {
        color = "#544628";
    }
    return color;
}
const iconsSteps = {
    0: <DriveFileRenameOutline />,
    1: <MergeType />,
    2: <Description />,
    3: <PictureAsPdf />,
    4: <Coronavirus />,
};
const LabelSteps = {
    0: "Name:",
    1: "Type:",
    2: "Description:",
    3: "PDF File:",
    4: "Artifacts:",
};
class AddEditPackage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            packageTypes: [],
            typeId: "",
            pdfFile: null,
            finishMessage: "",
            name: this.props.editMode.enable === true ? this.props.editMode.package.name : "",
            typeName: this.props.editMode.enable === true ? this.props.editMode.package.type : "",
            pdfName: this.props.editMode.enable === true ? this.props.editMode.package.anexo : "",
            description: this.props.editMode.enable === true ? this.props.editMode.package.description : "",
            artifactsLabel: this.props.editMode.enable === true ? this.getInitialArtifactsLabel() : "",
            artifactsSelected: this.props.editMode.enable === true ? this.props.editMode.package.artifacts : [],
        };
        this.labels = this.props.editMode.enable === true ? EditModelLabels : AddModeLabels;
        this.packageData = null;
    }

    componentDidMount() {
        this.getPackageTypes();
    }

    getInitialArtifactsLabel = () => {
        let artifactsLabel = "";
        this.props.editMode.package.artifacts.forEach(artifact => artifactsLabel = artifactsLabel + artifact.name + ", ");
        return artifactsLabel;
    }

    getPackageTypes = async () => {
        const response = JSON.parse(localStorage.getItem("packageTypes"));
        let types = [];
        response?.map((type) => types.push({ name: type.name, id: type._id }));
        const type = types.find(type => type.name === this.state.typeName);
        this.setState({ packageTypes: types, typeId: type ? type.id : "" });
    }

    PostPackage = async () => {
        const packageData = {
            name: this.state.name.trim(),
            description: this.state.description,
            fk_artifacts: this.state.artifactsSelected.map(artifact => artifact?.id ? artifact.id : artifact),
            type: this.state.typeId,
            anexo: this.state.pdfFile,
        };
        const responseIsOk = await postPackageAsync(this.props.DataUser.token, packageData);
        return responseIsOk;
    }

    PostChangePackage = () => {
        const id = this.props.editMode.package.id;
        const artifactsId = this.state.artifactsSelected.map(artifact => artifact?.id ? artifact.id : artifact);
        const packageData = {
            name: this.state.name.trim(),
            description: this.state.description,
            fk_artifacts: artifactsId,
            type: this.state.typeId,
            anexo: this.state.pdfFile,
        };
        const responseIsOk = updatePackageAsync(this.props.DataUser.token, id, packageData);
        return responseIsOk;
    }

    handleNext = () => {
        const previewStep = this.state.activeStep;
        this.setState({ activeStep: previewStep + 1 });
    }

    handleBack = () => {
        const previewStep = this.state.activeStep;
        this.setState({ activeStep: previewStep - 1 });
    }

    handleFinish = async () => {
        let responseIsOk = false;
        if (this.props.editMode.enable === true) {
            responseIsOk = await this.PostChangePackage();
        }
        else {
            responseIsOk = await this.PostPackage();
        }
        let finishMessage;
        if (responseIsOk === true) {
            finishMessage = this.labels.forms.finish.succesfuly;
            NotifySuccess(finishMessage);
        } else {
            finishMessage = this.labels.forms.finish.unsuccesfuly;
            NotifyError(finishMessage);
        }
        this.setState({ finishMessage: finishMessage });
        this.handleNext();
    }

    renderStepName = () => {
        return (
            <NameForm
                title={this.labels.forms.name}
                valueInput={this.state.name}
                handleChange={change => this.setState({ name: change })}
                handleNext={this.handleNext}
                handleBack={this.handleBack}>
            </NameForm>
        );
    }

    renderStepType = () => {
        return (
            <TypeForm
                title={this.labels.forms.type}
                valueInput={this.state.typeId}
                packageTypes={this.state.packageTypes}
                handleChange={(typeName, typeId) => this.setState({ typeName: typeName, typeId: typeId })}
                handleNext={this.handleNext}
                handleBack={this.handleBack}>
            </TypeForm>
        );
    }

    renderStepDescription = () => {
        return (
            <DescriptionForm
                title={this.labels.forms.description}
                valueInput={this.state.description}
                handleChange={(change) => {
                    if (change !== " ") {
                        this.setState({ description: change });
                    } else {
                        this.setState({ description: "" });
                    }
                }}
                handleNext={this.handleNext}
                handleBack={this.handleBack}>
            </DescriptionForm>
        );
    }

    renderStepPdf = () => {
        return (
            <PdfForm
                title={this.labels.forms.pdfFile}
                valueName={this.state.pdfName}
                handleChange={change => this.setState({ pdfFile: change.files[0], pdfName: change?.files[0]?.name })}
                handleDeleted={() => this.setState({ pdfFile: null, pdfName: "" })}
                handleNext={this.handleNext}
                handleBack={this.handleBack}>
            </PdfForm>
        );
    }

    handleArtifactsChange = (artifactsSelected) => {
        let artifactsLabel = "";
        artifactsSelected.forEach(artifact => artifactsLabel = artifactsLabel + artifact.name + ", ");
        this.setState({ artifactsSelected: artifactsSelected, artifactsLabel: artifactsLabel });
    }

    renderStepArtifacts = () => {
        return (
            <ArtifactsForm
                title={this.labels.forms.artifacts}
                valueInput={this.state.artifactsSelected}
                onArtifactsChange={this.handleArtifactsChange}
                handleNext={this.handleFinish}
                handleBack={this.handleBack}
                DataUser={this.props.DataUser}
                labelDisplay={this.state.artifactsLabel}
                EditMode={this.props.editMode}
            />
        )
    }

    renderFinishStepper = () => {
        return (
            <Paper sx={{bgcolor:"transparent", boxShadow:"0px 0px 0px 0px transparent"}}>
                <div className="rt-Input-Center">
                    <Typography variant="pageTitle" color={"white"}>{this.state.finishMessage}</Typography>
                </div>
                <PageButton onClick={this.props.onClose}>Accept</PageButton>
            </Paper>
        );
    }

    getSliceLabel = (label) => {
        label = label ?? "";
        let complement = "";
        if (label.length > 100) {
            complement = "....";
            label = label.slice(0, 100);
        }
        return label + complement;
    }

    renderStepper = () => {
        return (
            <Box style={{ backgroundColor: "#242933" }}>
                <Grid container spacing={0}>
                    <Grid item md={3}>
                        <Box className='StepIcon' style={{ backgroundColor: StepColor(0, this.state.activeStep) }}>
                            {iconsSteps[0]}
                        </Box>
                    </Grid>
                    <Grid item md={9}>
                        <Typography variant="h6"> {LabelSteps[0]}</Typography>
                    </Grid>
                    <Grid item md={3}>
                        <Typography align='center' variant='h4' color={StepColor(0 + 1, this.state.activeStep)}>|</Typography>
                    </Grid>
                    <Grid item md={9}>
                        <Typography >{this.getSliceLabel(this.state.name)}</Typography>
                    </Grid>
                    <Grid item md={3}>
                        <Box className='StepIcon' style={{ backgroundColor: StepColor(1, this.state.activeStep) }}>
                            {iconsSteps[1]}
                        </Box>
                    </Grid>
                    <Grid item md={9}>
                        <Typography variant="h6"> {LabelSteps[1]}</Typography>
                    </Grid>
                    <Grid item md={3}>
                        <Typography align='center' variant='h4' color={StepColor(2, this.state.activeStep)}>|</Typography>
                    </Grid>
                    <Grid item md={9}>
                    <Typography>{this.state.typeName}</Typography>
                    </Grid>
                    <Grid item md={3}>
                        <Box className='StepIcon' style={{ backgroundColor: StepColor(2, this.state.activeStep) }}>
                            {iconsSteps[2]}
                        </Box>
                    </Grid>
                    <Grid item md={9}>
                        <Typography variant="h6"> {LabelSteps[2]}</Typography>
                    </Grid>
                    <Grid item md={3}>
                        <Typography align='center' variant='h4' color={StepColor(3, this.state.activeStep)}>|</Typography>
                    </Grid>
                    <Grid item md={9}>
                    <Typography>{this.getSliceLabel(this.state.description)}</Typography>

                    </Grid>
                    <Grid item md={3}>
                        <Box className='StepIcon' style={{ backgroundColor: StepColor(3, this.state.activeStep) }}>
                            {iconsSteps[3]}
                        </Box>
                    </Grid>
                    <Grid item md={9}>
                        <Typography variant="h6"> {LabelSteps[3]}</Typography>
                    </Grid>
                    <Grid item md={3}>
                        <Typography align='center' variant='h4' color={StepColor(4, this.state.activeStep)}>|</Typography>
                    </Grid>
                    <Grid item md={9}>
                    <Typography>{this.getSliceLabel(this.state.pdfName)}</Typography>
                    </Grid>
                    <Grid item md={3}>
                        <Box className='StepIcon' style={{ backgroundColor: StepColor(4, this.state.activeStep) }}>
                            {iconsSteps[4]}
                        </Box>
                    </Grid>
                    <Grid item md={9}>
                        <Typography variant="h6"> {LabelSteps[4]}</Typography>
                    </Grid>
                    <Grid item md={3}>
                        <Typography align='center' variant='h4' color={StepColor(5, this.state.activeStep)}></Typography>
                    </Grid>
                    <Grid item md={9}>
                    <Typography>{this.getSliceLabel(this.state.artifactsLabel)}</Typography>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    render() {
        return (
            <Modal open={this.props.open} onClose={this.props.onClose} onBackdropClick={this.props.onClose}>
                <Box className="rt-Modal">
                    <Box>
                        <Grid container spacing={4}>
                            <Grid item xs={3}>
                                {this.renderStepper()}
                            </Grid>
                            <Grid item xs={9}>
                                {this.state.activeStep === 0 ? this.renderStepName() : null}
                                {this.state.activeStep === 1 ? this.renderStepType() : null}
                                {this.state.activeStep === 2 ? this.renderStepDescription() : null}
                                {this.state.activeStep === 3 ? this.renderStepPdf() : null}
                                {this.state.activeStep === 4 ? this.renderStepArtifacts() : null}
                                {this.state.activeStep === 5 ? this.renderFinishStepper() : null}
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Modal>
        );
    }
}

export default AddEditPackage;