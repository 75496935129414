import { env2 } from "../Helpers/Instance";
import { endPoint } from "./EndPoint";
import { getRequest, postAppJsonRequest } from "./FetchRequests";
import { getMsgResult, getResultAndShowSuccess } from "./ResponseValidation";

const CLIENT_DELETED = "Client deleted successfully";

export async function getClientsAsync(token, size, Nopage) {
  const url = env2.api + endPoint.clients;
  const response = await getRequest(token, url);
  return getMsgResult(response);
}

export async function getOnlineClientsAsync(token) {
  const url = env2.api + endPoint.onlineClients;
  const response = await getRequest(token, url);
  return getMsgResult(response);
}

export async function deleteClient(token, clientId) {
  const url = env2.api + endPoint.deleteClient + clientId;

  var requestHeaders = new Headers();
  requestHeaders.append("Authorization", "Bearer " + token);

  var requestOptions = {
    method: "DELETE",
    headers: requestHeaders,
    redirect: "follow",
  };

  const response = await fetch(url, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));

  return getResultAndShowSuccess(response, CLIENT_DELETED);
}
export async function changeClient(token, clientId, body) {
  const url = env2.api + endPoint.changeGroup + clientId;
  const response = await postAppJsonRequest(token, url, body);
  return getMsgResult(response);
}

export async function changeAliasClient(token, clientId, body) {
  const url = env2.api + endPoint.updateAlias + clientId;
  const response = await postAppJsonRequest(token, url, body);
  return getMsgResult(response);
}
