import { env2 } from "../Helpers/Instance";
import { endPoint } from "./EndPoint";
import { getResultAndShowSuccess, getMsgResult, getMsgResultIOC } from "./ResponseValidation";
import { getRequest, postAppJsonRequest } from "./FetchRequests";

const IOC_CREATED     = "IoC validation created successful";//Desarrollo
const USER_DELETED     = "IoC validation deleted successful";//Desarrollo


export async function addIOCAsync(token, IOCData) { //Desarrollo
  const url = env2.api + endPoint.addIOCExec;


  const response = await postAppJsonRequest(token, url, JSON.stringify(IOCData));
  const resultOk = getResultAndShowSuccess(response, IOC_CREATED);
  return resultOk;
}

export async function getIOCAsyncID(token, id) { //Desarrollo
  const url = env2.api + endPoint.getIOC + id;
  const response = await getRequest(token, url);
  return getMsgResult(response);
}
export async function getIOResultCAsyncID(token, id) { //Desarrollo
  const url = env2.api + endPoint.getResultIOC + id;
  const response = await getRequest(token, url);
  return getMsgResultIOC(response);
}

export async function getAllIOC(token) { // Desarrollo
  const url = env2.api + endPoint.allIOC;
  const response = await getRequest(token, url);
  return getMsgResult(response);
}

export async function deleteIOC(token, IOCid) { //Desarrollo
  const url = env2.api + endPoint.desactiveIOC + IOCid;
  const response = await getRequest(token, url);
  return getResultAndShowSuccess(response, USER_DELETED);
}
