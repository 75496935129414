import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Modal,
  TextField,
  Tooltip,
} from "@mui/material";
import Select from "@mui/material/Select";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import ConnexionIcon from "../../../assets/images/connected-icon.svg";
import DesconnexionIcon from "../../../assets/images/not-connected-icon.svg";
import WindowIcon from "../../../assets/images/window-icon.svg";
import LinuxIcon from "../../../assets/images/linux-icon.svg";
import MacIcon from "../../../assets/images/apple-logo.svg";
import UnkwonIcon from "../../../assets/images/device-unknown-icon.svg";
import EyeIcon from "../../../assets/images/eye-icon.svg";
import ComputerIcon from "@mui/icons-material/Computer";
import { changeAliasClient, changeClient } from "../../../Services/Clients";
import { Check, EditOutlined } from "@mui/icons-material";
import { NotifyInfo } from "../../../Helpers/Scripts";

export default function DetailmodalAgent(props) {
  const { Client, SelectIcon, ipGroups, DataUser, DetailsOff } = props;
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState("panel1");
  const [group, setGroup] = useState(Client.group);
  const [aliasValue, setAliasValue] = useState(null);
  const [editMode, seteditMode] = useState(false);
  const [sendAction, setsendAction] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleOpen = () => {
    setOpen(true);
    setAliasValue(Client?.alias ? Client.alias : Client.hostname);
  };

  const handleClose = () => {
    setOpen(false);
    setAliasValue(null);
  };

  const ConvertIp = (IP) => {
    let ippublic = IP.split("::ffff:");
    return ippublic;
  };

  const handleGroup = async (event) => {
    setGroup(event.target.value);
    let body = {
      fk_subGroup: event.target.value,
    };
    await changeClient(DataUser.token, Client.id, JSON.stringify(body));
  };
  const handleValueAlias = (event) => {
    let element = event.target.value;
    setAliasValue(element);
  };
  const handleAlias = async () => {
    let body = {
      alias: aliasValue,
    };
    await changeAliasClient(DataUser.token, Client.id, JSON.stringify(body));
    props.Reload();
    seteditMode(!editMode);
  };
  const sendRestart = () => {
    setsendAction(true);
    NotifyInfo("Send restart action");
    props.ResetInsider(Client.id);
  };
  useEffect(() => {
    if (sendAction) {
      setTimeout(() => {
        setsendAction(false);
      }, 5000);
    }
  }, [sendAction]);
  const body = (
    <Box
      className="Section"
      style={{ backgroundcolor: "#66638f", borderBottom: "1px solid #1e1c3a" }}
    >
      <Typography align="center" variant="h5" color={{ color: "#fff" }}>
        Endpoint Details
      </Typography>
      {Client ? (
        <Box style={{ marginTop: "20px" }}>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              style={{ color: "#fff", backgroundColor: "rgb(13, 11, 31)" }}
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                General Information
              </Typography>
              <Typography sx={{ color: "rgb(102, 99, 143)" }}>
                {Client?.hostname
                  ? Client.hostname
                  : Client.workUser[0].hostname}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item md={4}>
                  <Typography style={{ color: "#fff" }}>
                    Connection Status:
                    <Typography style={{ color: "rgb(102, 99, 143)" }}>
                      {Client.online ? (
                        <Box
                          style={{
                            fontWeight: "600",
                            fontSize: "13px",
                            lineHeight: "22px",
                            color: "#38e25d",
                            textAlign: "center",
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={ConnexionIcon}
                            alt=""
                            style={{ marginRight: ".5rem" }}
                          />
                          Connected
                        </Box>
                      ) : (
                        <Box
                          style={{
                            fontWeight: "600",
                            fontSize: "13px",
                            lineHeight: "22px",
                            color: "#ff4747",
                            textAlign: "center",
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={DesconnexionIcon}
                            alt=""
                            style={{ marginRight: ".5rem" }}
                          />
                          Not Connected
                        </Box>
                      )}
                    </Typography>
                  </Typography>
                </Grid>

                <Grid item md={4}>
                  <Typography style={{ color: "#fff" }}>
                    Host Name:
                    <Typography style={{ color: "rgb(102, 99, 143)" }}>
                      {Client?.hostname
                        ? Client.hostname
                        : Client.workUser[0].hostname}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography style={{ color: "#fff" }}>
                    Alias
                    <Typography style={{ color: "rgb(102, 99, 143)" }}>
                      {!editMode ? (
                        <>
                          {aliasValue}
                          <IconButton
                            size="small"
                            onClick={() => {
                              seteditMode(!editMode);
                            }}
                          >
                            <EditOutlined color="secondary" />
                          </IconButton>
                        </>
                      ) : (
                        <>
                          {" "}
                          <TextField
                            variant="standard"
                            value={aliasValue}
                            onChange={handleValueAlias}
                          ></TextField>
                          <IconButton size="small" onClick={handleAlias}>
                            <Check color="secondary" />
                          </IconButton>
                        </>
                      )}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography style={{ color: "#fff" }}>
                    Isolation Status:
                    <Typography style={{ color: "rgb(102, 99, 143)" }}>
                      {Client.isolation === true ? (
                        <Typography style={{ color: "red" }}>
                          Isolation Enabled
                        </Typography>
                      ) : null}
                      {Client.isolation === false ? (
                        <Typography style={{ color: "green" }}>
                          Isolation Disabled
                        </Typography>
                      ) : null}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography style={{ color: "#fff" }}>
                    Public Ip address:
                    <Typography style={{ color: "rgb(102, 99, 143)" }}>
                      {Client?.ip
                        ? Client.ip
                        : ConvertIp(Client.IPInformation[0].public.IP)}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography style={{ color: "#fff" }}>
                    Private IP address:
                    <Typography style={{ color: "rgb(102, 99, 143)" }}>
                      {Client?.private
                        ? Client.private
                        : Client.IPInformation[0].private.IP}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography style={{ color: "#fff" }}>
                    Restart Insider:
                    <Typography>
                      {!sendAction ? (
                        <Button
                          onClick={sendRestart}
                          size="small"
                          variant="contained"
                          color="secondary"
                          disabled={!Client.online}
                        >
                          Send
                        </Button>
                      ) : (
                        <LinearProgress
                          style={{ width: "50%", marginTop: "7px" }}
                          color="secondary"
                        />
                      )}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography style={{ color: "#fff" }}>
                    Port:
                    <Typography style={{ color: "rgb(102, 99, 143)" }}>
                      {Client?.port
                        ? Client.port
                        : Client.IPInformation[0].port}
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
              style={{ color: "#fff", backgroundColor: "rgb(13, 11, 31)" }}
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                Work Users
              </Typography>
              <Typography sx={{ color: "rgb(102, 99, 143)" }}>
                {Client.workUser[0].usuario}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item md={4}>
                  <Typography style={{ color: "#fff" }}>
                    Work Environments:
                    <Typography style={{ color: "rgb(102, 99, 143)" }}>
                      {Client.workUser[0].workEnvironments +
                        "\\\\" +
                        Client.workUser[0].typeEnvironment}
                    </Typography>
                  </Typography>
                </Grid>

                <Grid item md={4}>
                  <Typography style={{ color: "#fff" }}>
                    User:
                    <Typography style={{ color: "rgb(102, 99, 143)" }}>
                      {Client.workUser[0].usuario}
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
              style={{ color: "#fff", backgroundColor: "rgb(13, 11, 31)" }}
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                OS Information
              </Typography>
              <Typography sx={{ color: "rgb(102, 99, 143)" }}>
                {Client.osInformation[0].name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item md={6}>
                  <Typography style={{ color: "#fff" }}>
                    Platform:{" "}
                    {Client.OS === 1 ? (
                      <img
                        src={WindowIcon}
                        alt=""
                        style={{ marginLeft: "7px", width: "5%" }}
                      />
                    ) : null}
                    {Client.OS === 2 ? (
                      <img
                        src={LinuxIcon}
                        alt=""
                        style={{ marginLeft: "7px", width: "5%" }}
                      />
                    ) : null}
                    {Client.OS === 3 ? (
                      <img
                        src={MacIcon}
                        alt=""
                        style={{ marginLeft: "7px", width: "5%" }}
                      />
                    ) : null}
                    {Client.OS === 0 ? (
                      <img
                        src={UnkwonIcon}
                        alt=""
                        style={{ marginLeft: "7px", width: "5%" }}
                      />
                    ) : null}
                    <Typography style={{ color: "rgb(102, 99, 143)" }}>
                      {Client.osInformation[0].platform}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography style={{ color: "#fff" }}>
                    Name:
                    <Typography style={{ color: "rgb(102, 99, 143)" }}>
                      {Client.osInformation[0].name}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography style={{ color: "#fff" }}>
                    Version:
                    <Typography style={{ color: "rgb(102, 99, 143)" }}>
                      {Client.osInformation[0].version}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography style={{ color: "#fff" }}>
                    Compiled:
                    <Typography style={{ color: "rgb(102, 99, 143)" }}>
                      {Client.osInformation[0].compiled}
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
              style={{ color: "#fff", backgroundColor: "rgb(13, 11, 31)" }}
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                Hardware Information
              </Typography>
              <Typography sx={{ color: "rgb(102, 99, 143)" }}>
                {Client.hardwareInformation[0].CPUName}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item md={4}>
                  <Typography style={{ color: "#fff" }}>
                    CPU Brand:
                    <Typography style={{ color: "rgb(102, 99, 143)" }}>
                      {Client.hardwareInformation[0].CPUBrand}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography style={{ color: "#fff" }}>
                    CPU Speed:
                    <Typography style={{ color: "rgb(102, 99, 143)" }}>
                      {Client.hardwareInformation[0].CPUSpeed / 1000 + "GHz"}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography style={{ color: "#fff" }}>
                    CPU Name:
                    <Typography style={{ color: "rgb(102, 99, 143)" }}>
                      {Client.hardwareInformation[0].CPUName}
                    </Typography>
                  </Typography>
                </Grid>

                <Grid item md={4}>
                  <Typography style={{ color: "#fff" }}>
                    RAM:
                    <Typography style={{ color: "rgb(102, 99, 143)" }}>
                      {Math.ceil(
                        Client.hardwareInformation[0].RAM / 1073741824
                      )}
                      Gb
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography style={{ color: "#fff" }}>
                    Hard Drive Capacity:
                    <Typography style={{ color: "rgb(102, 99, 143)" }}>
                      {Math.ceil(
                        Client.hardwareInformation[0].hardDriveCapacity /
                          1073741824
                      )}
                      Gb
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
              aria-controls="panel5bh-content"
              id="panel4bh-header"
              style={{ color: "#fff", backgroundColor: "rgb(13, 11, 31)" }}
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                Agent Information
              </Typography>
              <Typography sx={{ color: "rgb(102, 99, 143)" }}>
                {Client?.fk_agent ? Client.fk_agent.name : "N/A"}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item md={4}>
                  <Typography style={{ color: "#fff" }}>
                    Name:
                    <Typography style={{ color: "rgb(102, 99, 143)" }}>
                      {Client?.fk_agent ? Client.fk_agent.name : "N/A"}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography style={{ color: "#fff" }}>
                    Version:
                    <Typography style={{ color: "rgb(102, 99, 143)" }}>
                      {Client.lastVersion ? Client.lastVersion : "N/A"}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography style={{ color: "#fff" }}>
                    Platform:
                    <Typography style={{ color: "rgb(102, 99, 143)" }}>
                      {Client?.fk_agent ? Client.fk_agent.platform : "N/A"}
                    </Typography>
                  </Typography>
                </Grid>
                {DetailsOff ? (
                  <Grid item md={4}>
                    <Typography style={{ color: "#fff" }}>
                      Group:
                      <Select
                        value={group}
                        style={{ color: "rgb(102, 99, 143)" }}
                        onChange={handleGroup}
                      >
                        {ipGroups.map((elem) => {
                          return (
                            <MenuItem key={elem._id} value={elem._id}>
                              {elem.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Typography>
                  </Grid>
                ) : null}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
      ) : null}
    </Box>
  );

  return (
    <Box style={{ display: "inline-block", cursor: "pointer" }}>
      {SelectIcon ? (
        <Tooltip title="Details">
          <IconButton className="iconButtonSize" onClick={handleOpen} >
            <img src={EyeIcon} alt="" />
          </IconButton>
        </Tooltip>
      ) : (
        <ComputerIcon
          onClick={handleOpen}
          style={{
            fill: Client.online ? "green" : "red",
            fontSize: "45px",
          }}
        />
      )}

      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
    </Box>
  );
}
