import { endPoint } from "./EndPoint";
import { env2 } from "../Helpers/Instance";
import { postAppJsonRequest, getRequest } from "./FetchRequests";
import { getMsg, getMsgDash, getMsgResult } from "./ResponseValidation";
import moment from "moment";

export async function GetTop10AgentsAsync(token) {
  const url = env2.api + endPoint.topAgentsSuccesfully;
  const response = await getRequest(token, url);
  return getMsgDash(response,"Top 10 Targeted Endpoints");
}

export async function getReportByExecutionIdAsync(token, executionId,body) {
  const url = env2.api + endPoint.getListExecutions + executionId;
  const response = await postAppJsonRequest(token, url,body);
  return getMsg(response);
}

export async function getReportData(token, executionId,body=[]) {
  const url = env2.api + endPoint.getInfoExecution + executionId;
  const response = await postAppJsonRequest(token, url, body);
  return getMsg(response);
}

export async function getHistoryExecutionsCountAsync(token) {
  const url = env2.api + endPoint.historyExecutionCount;
  const response = await getRequest(token, url);
  return getMsgResult(response);
}

export async function getRecordByDayAsync(token, date, GMT, Days) {
  const url = env2.api + endPoint.historyRecordCount;
  const bodyData = {
    todayIs: moment(date).format("YYYY-MM-DD"),
    utc: GMT,
    goBackDay: Days
  };
  const body = JSON.stringify(bodyData);
  const response = await postAppJsonRequest(token, url, body);
  return getMsgDash(response,"Events TImeline");
}

export async function GetTops5ArtifactsAxync(token) {
  const url = env2.api + endPoint.topArtifact;
  const response = await getRequest(token, url);
  return getMsgDash(response,"Most Successful Threats");
}

export async function GetTopAgentsAsync(token) {
  const url = env2.api + endPoint.topAgents;
  const response = await getRequest(token, url);
  return getMsgDash(response,"Most Vulnerable Endpoints");
}

export async function GetClientReportAsync(token, id) {
  const url = env2.api + endPoint.clientReport + id + "/page/10/1";
  const response = await getRequest(token, url);
  return getMsg(response);
}

export async function getDataReportCustomHistory(token,executionId,body){
  const url = env2.api + endPoint.reportDeatiled + executionId;
  const response = await postAppJsonRequest(token, url, JSON.stringify(body));
  return getMsg(response);
}
