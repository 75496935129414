import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  StyledDataGrid,
  TableBox,
  STATUS_TAG,
  PACKAGE_TYPE_TAG,
  ButtonActions,
  CustomLoadingOverlay,
  FormInputTextField,
  PrimaryButton,
  pageTheme,
} from "../../../Helpers/Themes";
import {
  Box,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  Select,
  ThemeProvider,
  Tooltip,
  MenuItem,
  Typography,
  Menu,
  TablePagination,
} from "@mui/material";
import { Clear, Delete, Search } from "@mui/icons-material";
import EditIcon from "../../../assets/images/edit-action-icon.svg";
import EyeIcon from "../../../assets/images/eye-icon.svg";
import { FilterAlt } from "@mui/icons-material";
import { GridOverlay } from "@mui/x-data-grid-pro";
import axios from "axios";
import { env2 } from "../../../Helpers/Instance";
import { endPoint } from "../../../Services/EndPoint";
import Cookies from "universal-cookie";
import AxiosFetch from "../../../Services/FetchServices";
import { GetDate } from "../../../Helpers/Scripts";
import {
  desactivatePackageById,
  getAnexoPackage,
  getPackageByIdAsync,
} from "../../../Services/Package";
import AddEditPackage from "./AddPackage/AddEditPackage";
import DeleteWindow from "./Common/DeleteWindow";
import PackageDetails from "./Details/PackageDetails";
import IconPackage from "../../../assets/images/package-icon.svg";
import IconPackageWhite from "../../../assets/images/package-icon-white.svg";
import { MoreVert } from "@mui/icons-material";
import ExporExcelMiddle from "../../../Helpers/ExporExcelMiddle";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function PackagePage(props) {
  const { DataUser, SelectedPage } = props;
  const [FilterName, setFilterName] = useState("");
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [lastValuePage, setlastValuePage] = useState(0);
  const [page, setPage] = useState(0);
  const [rowCount, setrowCount] = useState(0);
  const [sortModel, setSortModel] = useState([
    { field: "lastModification", sort: "desc" },
  ]);

  const [TypeList, setTypeList] = useState([]);

  const [FilterType, setFilterType] = useState([]);
  const [FilterStatus, setFilterStatus] = useState([]);
  const [FilterActive, setFilterActive] = useState(false);
  const [showEl, setShowel] = React.useState(null);
  const [loading, setLoading] = useState(false);
  const [signalReload, setsignalReload] = useState(0);
  const cookies = useMemo(() => new Cookies(), []); // Wrap in useMemo with an empty dependency array
  const [packageDetails, setpackageDetails] = useState(null);
  const [openAddEditPackage, setopenAddEditPackage] = useState(false);
  const [openPackageDetails, setopenPackageDetails] = useState(false);
  const [openDeletePackage, setopenDeletePackage] = useState({
    open: false,
    packageFocus: { id: "", name: "" },
  });
  const [editPackageMode, seteditPackageMode] = useState({
    enable: false,
    package: null,
  });

  const handleOpenDetails = async (id) => {
    let response = await getPackageByIdAsync(localStorage.getItem("token"), id);

    if (response) {
      setpackageDetails(response);
      setopenPackageDetails(true);
    }
  };

  const handleEditPackage = async (id) => {
    let response = await getPackageById(id);
    seteditPackageMode({ package: response, enable: true });
    setopenAddEditPackage(true);
  };

  const abrir = Boolean(showEl);
  const handleClick = (event) => {
    setShowel(event.currentTarget);
  };
  const handleClose = () => {
    setShowel(null);
  };

  const TagTestLabels = [
    {
      id: 1,
      name: "Tested",
      value: "tested",
    },
    {
      id: 2,
      name: "Partially Tested",
      value: "PartiallyTested",
    },
    {
      id: 3,
      name: "Untested",
      value: "untested",
    },
  ];

  const HandleFilterName = (e) => {
    let x = e.target.value.substring(0, 1);
    if (x !== " ") {
      if (e.target.value !== "") {
        setPage(0);
        setFilterName(e.target.value);
        setFilterActive(true);
      } else {
        setFilterName("");
      }
    }
  };
  const CheckWhatChange = useCallback(() => {
    let flag = false;
    if (lastValuePage !== page) {
      flag = true;
    }
    return flag;
  }, [page, lastValuePage]);

  const ConstructorBody = useCallback(() => {
    var ArrayBody = {};
    if (FilterName) {
      ArrayBody.name = FilterName;
    }
    if (FilterType.length > 0) {
      ArrayBody.type = FilterType;
    }
    if (FilterStatus.length > 0) {
      ArrayBody.tagTest = FilterStatus;
    }

    let jsonReturn = JSON.stringify(ArrayBody);

    return jsonReturn;
  }, [FilterStatus, FilterName, FilterType]);

  const handleType = (event) => {
    const {
      target: { value },
    } = event;
    if (value.length > 0) {
      setFilterActive(true);
    } else {
      if (FilterType.length === 0 && FilterName === "") {
        setFilterActive(false);
      }
    }
    setlastValuePage(0);
    setPage(0);
    setFilterType(typeof value === "string" ? value.split(",") : value);
  };

  const handleStatus = (event) => {
    const {
      target: { value },
    } = event;
    if (value.length > 0) {
      setFilterActive(true);
    } else {
      if (FilterStatus.length === 0 && FilterName === "") {
        setFilterActive(false);
      }
    }
    setlastValuePage(0);
    setPage(0);
    setFilterStatus(typeof value === "string" ? value.split(",") : value);
  };

  const CleanFilter = () => {
    setFilterName("");
    setFilterType([]);
    setFilterStatus([]);
  };

  const handleChangePage = (event, newPage) => {
    setlastValuePage(page);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setlastValuePage(0);
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  const downloadPdfFile = () => {
    getAnexoPackage(
      localStorage.getItem("token"),
      packageDetails.id,
      packageDetails.anexo
    );
  };
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const obj = {
      FilterName: "",
      FilterProcesState: "",
      FilterVector: "",
      FilterStatus: "",
      valueDate: [null, null],
    };
    cookies.set("DataFiltersPagination", JSON.stringify(obj), {
      path: "/",
      sameSite: "none",
      secure: true,
    });
    const defaultDataPAgination = { page: 0, rowsPerPage: 10 };
    cookies.set("DataPagination", JSON.stringify(defaultDataPAgination), {
      path: "/",
    });

    async function GetData() {
      const url = env2.api + endPoint.packageTypes;
      const response = await AxiosFetch("GET", url, source);
      if (response?.msg?.result) {
        let array = [];
        response.msg.result.forEach((element) => {
          array.push({
            id: element._id,
            name: element.name,
          });
        });
        setTypeList(array);
      } else {
        setTypeList([]);
      }
    }
    GetData();

    return () => {
      if (SelectedPage !== 5) source.cancel();
    };
  }, [cookies, SelectedPage]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    setLoading(true);
    async function GetData() {
      const baseUrl = `${env2.api}${endPoint.packagePageAdvanced}`;
      const paginationParams = `${rowsPerPage}/${
        CheckWhatChange() ? page + 1 : 1
      }`;
      const sortParams =
        sortModel.length > 0
          ? `col=${sortModel[0].field}&order=${sortModel[0].sort}`
          : `col=lastModification&order=desc`;

      const url = `${baseUrl}${paginationParams}?${sortParams}`;
      const data = await AxiosFetch("POST", url, source, ConstructorBody());
      const response = data?.msg ? data.msg : null;
      if (response?.result && Array.isArray(response.result.packages)) {
        const packagesDto = response.result.packages.map((p) => {
          let packageDto = {
            id: p.id,
            name: p.name,
            lastModification: GetDate(p.lastModification),
            tagTest: p.tagTest,
            type: p.type,
            description: p.description,
            artifacts: p.artifacts,
          };
          return packageDto;
        });
        let total = response?.result?.info?.neto
          ? response.result.info.neto
          : 0;
        let validPackage = packagesDto === undefined ? [] : packagesDto;
        setRows(validPackage);
        setrowCount(total);
      } else {
        setRows([]);
        setrowCount(0);
      }
    }
    GetData();
    setLoading(false);

    return () => {
      if (SelectedPage !== 5) source.cancel();
    };
  }, [
    page,
    rowsPerPage,
    sortModel,
    ConstructorBody,
    CheckWhatChange,
    FilterActive,
    FilterName,
    FilterStatus,
    FilterType,
    signalReload,
    SelectedPage,
  ]);

  const handleAddPckage = () => {
    seteditPackageMode({ package: null, enable: false });

    setopenAddEditPackage(true);
  };
  const getPackageById = async (id) => {
    const response = await getPackageByIdAsync(
      localStorage.getItem("token"),
      id
    );
    const artifacts = response?.artifacts
      ? Array.isArray(response.artifacts)
        ? response.artifacts.map((artifact) => ({
            id: artifact.id,
            name: artifact.name,
            threatType: artifact.threatType,
            file: artifact.file,
            phaseAttacks: artifact.phaseAttacks,
            md5: artifact.md5,
          }))
        : [
            {
              id: response.artifacts.id,
              name: response.artifacts.name,
              threatType: response.artifacts.threatType,
              file: response.artifacts.file,
              phaseAttacks: response.artifacts.phaseAttacks,
              md5: response.artifacts.md5,
            },
          ]
      : [];
    if (response) {
      let packageDto = {
        id: response?.id,
        name: response?.name,
        description: response?.description,
        type: response?.type ? response?.type : "undefined",
        status: response?.tagTest,
        artifacts: artifacts,
        anexo: response?.anexo,
        createdAt: GetDate(response?.createdAt),
        createdByUser: response?.createdBy?.userName,
        createdByEmail: response?.createdBy?.email,
        updatedAt: response?.updatedAt ? GetDate(response?.updatedAt) : "",
        lastModificationByUser: response?.lastModificationBy?.userName,
        lastModificationByEmail: response?.lastModificationBy?.email,
      };
      return packageDto;
    } else {
      return null;
    }
  };

  const onCloseAddPackage = () => {
    setopenAddEditPackage(false);
    setsignalReload(signalReload + 1);
  };

  const handleOpenDeletePackage = (e) => {
    setopenDeletePackage({ open: true, packageFocus: { id: e, name: e } });
  };

  const handleCloseDelete = () => {
    setopenDeletePackage({ open: false, packageFocus: { id: "", name: "" } });
    setsignalReload(signalReload + 1);
  };

  const handleConfirmDeletePackage = async () => {
    await desactivatePackageById(
      localStorage.getItem("token"),
      openDeletePackage.packageFocus.id
    );
    setopenDeletePackage({ open: false, packageFocus: { id: "", name: "" } });
    setPage(0);
    setsignalReload(signalReload + 1);
  };

  const onClosePackageDetails = () => {
    setopenPackageDetails(false);
  };

  const Columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1.2,
      minWidth: 200,

      renderCell: (params) => (
        <Typography
          style={{
            fontFamily: "Roboto , Helvetica,Arial,sans-serif",
            fontWeight: "400",
            fontSize: "0.875rem",
            maxWidth: "100%",
            cursor: "pointer",
            minWidth: "100px",
            overflow: "hidden",
            textAlign: "justify",
            marginLeft: "7px",
            display: "block",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
          id={params.id}
          name={params.name}
          onClick={() => handleOpenDetails(params.id)}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "type",
      headerName: "Type",
      sortable: true,
      filterable: false,
      flex: 0.7,
      minWidth: 150,
      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>Type</Box>
      ),
      renderCell: (params) => (
        <Box className={PACKAGE_TYPE_TAG[params.value].className}>
          {PACKAGE_TYPE_TAG[params.value].label}
        </Box>
      ),
    },
    {
      field: "tagTest",
      headerName: "Status",
      sortable: true,
      filterable: false,
      flex: 0.8,
      minWidth: 150,
      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>Status</Box>
      ),
      renderCell: (params) => (
        <Chip
          style={{ width: "120px" }}
          variant="outlined"
          label={STATUS_TAG[params.value].label}
          color={STATUS_TAG[params.value].chipColor}
        />
      ),
    },
    {
      field: "lastModification",
      headerName: "Date Uploaded",
      flex: 1,
      minWidth: 200,
      filterable: false,
      align: "center",

      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>
          {"Date Uploaded"}
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.8,
      align: "center",
      minWidth: 150,
      sortable: false,
      filterable: false,
      type: "actions",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>Actions</Box>
      ),
      renderCell: (params) => (
        <>
          <Box>
            <Tooltip title="Details">
              <IconButton
                className="iconButtonSize"
                onClick={() => {
                  handleOpenDetails(params.id);
                }}
              >
                <img id={params.id} name={params.name} src={EyeIcon} alt="" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
              <IconButton
                className="iconButtonSize"
                onClick={() => {
                  handleEditPackage(params.id);
                }}
              >
                <img id={params.id} name={params.name} src={EditIcon} alt="" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                className="iconButtonSize"
                onClick={() => {
                  handleOpenDeletePackage(params.id);
                }}
              >
                <Delete color="inherit" id={params.id} name={params.name} />
              </IconButton>
            </Tooltip>
          </Box>
        </>
      ),
    },
  ];
  const renderAddEditPackage = () => {
    return (
      <AddEditPackage
        open={openAddEditPackage}
        editMode={editPackageMode}
        onFinish={onCloseAddPackage}
        onClose={onCloseAddPackage}
        DataUser={DataUser}
      ></AddEditPackage>
    );
  };

  const renderDeletePackage = () => {
    return (
      <DeleteWindow
        open={openDeletePackage.open}
        message={"Do you want to delete this package?"}
        //message  = {"Do you want to delete " + openDeletePackage.packageFocus.name + " ?"}
        onCancel={handleCloseDelete}
        onDelete={handleConfirmDeletePackage}
      ></DeleteWindow>
    );
  };

  const renderPackageDetails = () => {
    return (
      <PackageDetails
        open={openPackageDetails}
        packageDetails={packageDetails}
        downloadPdfFile={downloadPdfFile}
        onClose={onClosePackageDetails}
        DataUser={DataUser}
      ></PackageDetails>
    );
  };
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileId = "page-menu-mobile";
  const renderMobileMenu = (
    <Box>
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id={mobileId}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <Box style={{ display: "inline-block" }}>
          <ExporExcelMiddle
            pageOrigin={"Packages"}
            fileName="E.V.E. Package Export"
          />
        </Box>
        <Tooltip
          title={rowCount > 0 ? "New package" : "No artifacts available"}
        >
          <Box style={{ display: "inline-block" }}>
            {rowCount > 0 ? (
              <PrimaryButton variant="outlined" onClick={handleAddPckage}>
                <img
                  src={IconPackageWhite}
                  alt=""
                  style={{ width: "20px", marginRight: "7px" }}
                />
                New package
              </PrimaryButton>
            ) : (
              <PrimaryButton
                variant="outlined"
                onClick={handleAddPckage}
                disabled
              >
                <img
                  src={IconPackageWhite}
                  alt=""
                  style={{ width: "20px", marginRight: "7px" }}
                />
                New package
              </PrimaryButton>
            )}
          </Box>
        </Tooltip>
      </Menu>
    </Box>
  );

  return (
    <Box style={{ width: "100%" }}>
      {openDeletePackage.open && renderDeletePackage()}
      {openAddEditPackage && renderAddEditPackage()}
      {openPackageDetails && renderPackageDetails()}
      <ThemeProvider theme={pageTheme}>
        <Typography variant="pageTitle">
          <img src={IconPackage} alt="" className="rt-icon-header" />
          Packages
          <Box
            sx={{
              float: { xs: "none", md: "right" },
              display: { xs: "none", md: "flex" },
            }}
          >
            <Box style={{ display: "inline-block" }}>
              <ExporExcelMiddle
                pageOrigin={"Packages"}
                fileName="E.V.E. Package Export"
              />
            </Box>

            <Tooltip
              title={rowCount > 0 ? "New package" : "No artifacts available"}
            >
              <Box style={{ display: "inline-block" }}>
                {rowCount > 0 ? (
                  <PrimaryButton variant="outlined" onClick={handleAddPckage}>
                    <img
                      src={IconPackageWhite}
                      alt=""
                      style={{ width: "20px", marginRight: "7px" }}
                    />
                    New package
                  </PrimaryButton>
                ) : (
                  <PrimaryButton
                    variant="outlined"
                    onClick={handleAddPckage}
                    disabled
                  >
                    <img
                      src={IconPackageWhite}
                      alt=""
                      style={{ width: "20px", marginRight: "7px" }}
                    />
                    New package
                  </PrimaryButton>
                )}
              </Box>
            </Tooltip>
          </Box>
          <Box
            sx={{
              float: { xs: "right", md: "none" },
              display: { xs: "inline-block", md: "none" },
            }}
          >
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreVert />
            </IconButton>
          </Box>
          {renderMobileMenu}
        </Typography>
        <Box style={TableBox}>
          <div style={{ height: "auto", width: "100%" }}>
            <FormInputTextField
              onChange={(e) => HandleFilterName(e)}
              color="secondary"
              variant="outlined"
              value={FilterName}
              label="Search name…"
              InputProps={{
                endAdornment: (
                  <IconButton
                    title="Clear"
                    aria-label="Clear"
                    size="small"
                    onClick={() => setFilterName("")}
                  >
                    {FilterName ? <Clear /> : <Search />}
                  </IconButton>
                ),
              }}
              style={{ marginBottom: "7px" }}
            />
            <ButtonActions
              id="basic-button"
              aria-controls={abrir ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={abrir ? "true" : undefined}
              onClick={handleClick}
              style={{ marginLeft: "15px" }}
            >
              <FilterAlt style={{ color: "white" }} />
              <Typography
                style={{ color: "white", textTransform: "capitalize" }}
              >
                Filters
              </Typography>
            </ButtonActions>
            <Menu
              id="basic-menu"
              anchorEl={showEl}
              open={abrir}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem>
                <FormControl
                  sx={{ m: 1, width: 300 }}
                  style={{ marginTop: "-1px" }}
                >
                  <InputLabel
                    sx={{
                      "& .MuiSvgIcon-root": {
                        color: "white",
                      },
                    }}
                    id="FilterTreat"
                    color="secondary"
                  >
                    Filter by Type
                  </InputLabel>
                  <Select
                    id="FilterPhase"
                    color="secondary"
                    variant="outlined"
                    value={FilterType}
                    onChange={handleType}
                    multiple
                    MenuProps={MenuProps}
                  >
                    {TypeList.length > 0
                      ? TypeList.map((Phase) => (
                          <MenuItem
                            style={{
                              backgroundColor:
                                FilterType.indexOf(Phase.id) > -1
                                  ? "purple"
                                  : "transparent",
                            }}
                            key={Phase.id}
                            value={Phase.id}
                          >
                            {Phase.name === "Interactiva"
                              ? "Interactive"
                              : Phase.name}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </MenuItem>
              <MenuItem>
                <FormControl
                  sx={{ m: 1, width: 300 }}
                  style={{ marginTop: "-1px" }}
                >
                  <InputLabel
                    sx={{
                      "& .MuiSvgIcon-root": {
                        color: "white",
                      },
                    }}
                    id="FilterTreat"
                    color="secondary"
                  >
                    Filter by Status
                  </InputLabel>
                  <Select
                    id="FilterPhase"
                    color="secondary"
                    variant="outlined"
                    value={FilterStatus}
                    onChange={handleStatus}
                    multiple
                    MenuProps={MenuProps}
                  >
                    {TagTestLabels.map((tag) => (
                      <MenuItem
                        style={{
                          backgroundColor:
                            FilterStatus.indexOf(tag.value) > -1
                              ? "purple"
                              : "transparent",
                        }}
                        key={tag.id}
                        value={tag.value}
                      >
                        {tag.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </MenuItem>
              <ButtonActions
                onClick={CleanFilter}
                style={{ marginLeft: "30%" }}
              >
                Clear Filters
              </ButtonActions>
            </Menu>

            <StyledDataGrid
              components={{
                LoadingOverlay: CustomLoadingOverlay,
                NoRowsOverlay: () => (
                  <GridOverlay>
                    <div>No Data Results</div>
                  </GridOverlay>
                ),
                NoResultsOverlay: () => (
                  <GridOverlay>
                    <div>No Data Results</div>
                  </GridOverlay>
                ),
                Pagination: () => (
                  <>
                    <TablePagination
                      component="div"
                      count={rowCount}
                      rowsPerPageOptions={[5, 10, 20]}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      showFirstButton
                      showLastButton
                    />
                  </>
                ),
              }}
              density="comfortable"
              pagination
              paginationMode="server"
              sortingMode="server"
              sortModel={sortModel}
              onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
              columns={Columns}
              rows={rows}
              loading={loading}
              disableColumnMenu={true}
              disableSelectionOnClick
              autoHeight
            />
          </div>
        </Box>
      </ThemeProvider>
    </Box>
  );
}
