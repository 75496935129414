import React, { useState } from "react";
import { Box } from "@mui/material";

import "../Incursion.scss";
import PackageColorIcon from "../../../assets/images/select-package.svg";
import PackageDetails from "../../Packages/Sections/Details/PackageDetails";
import {
  getAnexoPackage,
  getPackageByIdAsync,
} from "../../../Services/Package";
import { NotifyInfo } from "../../../Helpers/Scripts";

export default function PackModal(props) {
  const { Row, DataUser } = props;
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const handleOpen = async () => {
    let response = await getPackageByIdAsync(localStorage.getItem("token"),Row.id);
    if (response) {
      setData(response);
      setOpen(true);
    } else {
      NotifyInfo("Don't found package's detail");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const DownloadPDFFile = () => {
    getAnexoPackage(DataUser.token, Row.id, Row.anexo);
  };

  return (
    <Box>
      <img onClick={handleOpen} src={PackageColorIcon} alt="" />
      <PackageDetails
        open={open}
        packageDetails={data ? data : Row}
        downloadPdfFile={DownloadPDFFile}
        onClose={handleClose}
        DataUser={DataUser}
      ></PackageDetails>
    </Box>
  );
}
